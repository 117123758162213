import { FormEvent } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ActionItem } from '../../RestService/Models/ActionItem';
import { CreateActionItemRequest } from '../../RestService/Models/CreateActionItemRequest';
import { EditActionItemRequest } from '../../RestService/Models/EditActionItemRequest';
import RestService from '../../RestService/RestService';
import { UpdateActionItemsSelector } from '../../State/ActionItemsAtom';
import { ModalContentsAtom } from '../../State/ModalContentsAtom';
import { SessionAtom } from '../../State/SessionAtom';

import './ActionItemModal.scss';

interface Props {
	actionItem?: ActionItem;
}

interface FormElements extends HTMLFormControlsCollection {
	action: HTMLInputElement;
	assignee: HTMLInputElement;
}

interface ActionItemForm extends HTMLFormElement {
	readonly elements: FormElements;
}

function ActionItemModal({ actionItem }: Props) {
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const setActionItem = useSetRecoilState(
		UpdateActionItemsSelector(actionItem?.id)
	);
	const session = useRecoilValue(SessionAtom);

	function editActionItem(actionToSubmit: string, assignedTo: string) {
		const editActionItemRequest: EditActionItemRequest = {
			action: actionToSubmit,
			assignedTo: assignedTo,
			isDone: false,
		};
		RestService.editActionItem(actionItem?.id!, editActionItemRequest)
			.then(setActionItem)
			.catch(console.error)
			.finally(() => setModalContents(null));
	}

	function addActionItem(actionToSubmit: string, assignedTo: string) {
		const actionItemToAdd: CreateActionItemRequest = {
			sessionId: session?.id ?? '',
			action: actionToSubmit,
			assignedTo: assignedTo,
		};
		RestService.addActionItem(actionItemToAdd)
			.then(setActionItem)
			.catch((error) => {
				console.error('Failed to add action item. ', error);
			})
			.finally(() => setModalContents(null));
	}

	const saveActionItem = (event: FormEvent<ActionItemForm>) => {
		event.preventDefault();

		const form = event.currentTarget.elements;

		const actionToSubmit = form.action.value;
		const assignedTo = form.assignee.value;
		if (actionItem) {
			editActionItem(actionToSubmit, assignedTo);
		} else {
			addActionItem(actionToSubmit, assignedTo);
		}
	};

	return (
		<form
			className="form-container action-item-modal"
			onSubmit={saveActionItem}
			data-testid="actionItemModal__form"
		>
			<h1 className="action-item-modal__title">We will:</h1>
			<label className="input__label">
				Action:
				<textarea
					className="action-item-modal__textarea-action"
					data-testid="actionItemModal__action-textarea"
					aria-label="Write your action item here"
					id="action"
					defaultValue={actionItem?.action ?? ''}
					maxLength={255}
				/>
			</label>
			<label className="input__label">
				Assigned To:
				<textarea
					className="action-item-modal__textarea-assigned-to"
					data-testid="actionItemModal__assigned-to-textarea"
					aria-label="Optional, Write the name of the person you want to assign this action item to"
					id="assignee"
					defaultValue={actionItem?.assignedTo ?? ''}
				/>
			</label>
			<div className="action-item-modal__button-container">
				<button
					type="button"
					className="button__secondary"
					data-testid="actionItemModal__cancel-button"
					onClick={() => {
						setModalContents(null);
					}}
				>
					Cancel
				</button>
				<button
					type="submit"
					className="button__primary"
					data-testid="actionItemModal__save-button"
				>
					Save
				</button>
			</div>
		</form>
	);
}

export default ActionItemModal;
