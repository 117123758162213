import { EMOTICONSTANTS } from '../Constants';

import EmoticonImage from './EmoticonImage';

import './EmoticonRadioButton.scss';

interface Props {
	value: number;
	onEmoticonFocus: (index: number) => void;
	grayedOut: boolean;
	selectedEmoticon: number;
}

function EmoticonRadioButton({
	value,
	onEmoticonFocus,
	grayedOut,
	selectedEmoticon,
}: Props) {
	const emoticonText = EMOTICONSTANTS[value - 1];

	const emoticonTestId = `emoticon-radio-button-${value}_${emoticonText.toLowerCase()}`;

	return (
		<label
			className="emoticon__label"
			data-testid={`${emoticonTestId}`}
			aria-label={emoticonText}
		>
			<input
				type="radio"
				name="emoticons"
				value={value}
				className="emoticon__radio-button"
				onFocus={() => onEmoticonFocus(value)}
				onChange={() => onEmoticonFocus(value)}
				checked={selectedEmoticon === value}
			/>
			<EmoticonImage value={value} grayedOut={grayedOut} />
		</label>
	);
}

export default EmoticonRadioButton;
