import { LOGIN_PATH } from '../Routes/RouteConstants';

import './GoodbyePage.scss';

function GoodbyePage() {
	return (
		<div className="goodbye form-container">
			<h1>Goodbye!</h1>
			<p className="goodbye__description">
				You’ve successfully logged out.
			</p>
			<a href={LOGIN_PATH} className="button__primary">
				Log Back In
			</a>
		</div>
	);
}

export default GoodbyePage;
