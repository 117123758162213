import {useSetRecoilState} from 'recoil';

import RestService from '../../RestService/RestService';
import {ModalContentsAtom} from '../../State/ModalContentsAtom';

import './ConfirmEndSessionModal.scss';

function ConfirmEndSessionModal() {
	const setModalContents = useSetRecoilState(ModalContentsAtom);

	const closeModal = () => {
		setModalContents(null);
	};

	const endSession = () => {
		RestService.endSession(true)
			.then(() => {
				closeModal();
			})
			.catch(console.error);
	};

	return (
		<div className="confirm-end-session form-container">
			<h1 className="confirm-end-session__title">
				Do you want to end the health check for everybody?
			</h1>
			<p className="confirm-end-session__description">
				This will permanently archive all thoughts!
			</p>
			<div className="confirm-end-session__button-container">
				<button
					className="confirm-end-session__no-button button__secondary"
					data-testid="confirmEndSession__no-button"
					onClick={closeModal}
				>
					No
				</button>
				<button
					className="confirm-end-session__yes-button button__primary"
					data-testid="confirmEndSession__yes-button"
					onClick={endSession}
				>
					Yes
				</button>
			</div>
		</div>
	);
}

export default ConfirmEndSessionModal;
