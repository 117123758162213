import {NavLink} from 'react-router-dom';

import './BaseSecondaryNav.scss';

export interface NavItem {
	label: string;
	path: string;
	testId: string;
	isActive?: boolean;
}

interface Props {
	navItems: Array<NavItem>;
	className?: string;
}

function BaseSecondaryNav({navItems, className}: Props) {
	return (
		<div className={`base-secondary-nav  ${className ?? ''}`}>
			{navItems.map((navItem) => {

				function determineActiveClass(isActive: boolean) {
					return isActive ? " is-active" : "";
				}

				return (
					<NavLink
						key={`nav-item-${navItem.testId}`}
						to={navItem.path}
						className={({isActive}) =>
							"base-secondary-nav__link" + (navItem.isActive === undefined ?
								determineActiveClass(isActive) : determineActiveClass(navItem.isActive))}
						data-testid={navItem.testId}
					>
						{navItem.label}
					</NavLink>
				);
			})}
		</div>
	);
}

export default BaseSecondaryNav;
