import React from "react";

import DigImage from '../../Assets/LandingPage/dig.png';
import FeedbackImage from '../../Assets/LandingPage/feedback.png';
import FixImage from '../../Assets/LandingPage/fix.png';
import RelationshipsImage from '../../Assets/LandingPage/relationships.png';
import SafetyImage from '../../Assets/LandingPage/safety.png';

import './WhyVibezSection.scss';

type CaptionImage = {
	image: string;
	caption: string;
};

function WhyVibezSection(): React.ReactElement {
	const ImageWithCaption = ({ image, caption }: CaptionImage) => (
		<div className="why-vibez-section__image-with-caption">
			<img
				src={image}
				className="why-vibez-section__image-with-caption__image"
				alt=""
			/>
			<p className="why-vibez-section__image-with-caption__text">
				{caption}
			</p>
		</div>
	);

	return (
		<section className="why-vibez-section">
			<h1 className="why-vibez-section__title" aria-label="Why Vibes">
				Why Vibez
			</h1>
			<div className="why-vibez-section__content">
				<div className="why-vibez-section__top-row">
					<ImageWithCaption
						image={SafetyImage}
						caption="Increase trust and psychological safety within the team"
					/>
					<ImageWithCaption
						image={RelationshipsImage}
						caption="Build better relationships and support among team members"
					/>
					<ImageWithCaption
						image={DigImage}
						caption="Dig deeper and assess influences on your team, beyond last week’s delivery cycle"
					/>
				</div>
				<div className="why-vibez-section__bottom-row">
					<ImageWithCaption
						image={FixImage}
						caption="Nip problems in the bud before they become Big Problems"
					/>
					<ImageWithCaption
						image={FeedbackImage}
						caption="Foster a culture of feedback and incrementally improve the way teams work together"
					/>
				</div>
			</div>
		</section>
	);
}

export default WhyVibezSection;
