import Cookies from 'universal-cookie';

import { TOKEN_KEY } from '../RestService/ApiConstants';

const cookies = new Cookies();

const CookieService = {
	setToken: (accessToken: string): void => {
		cookies.set(TOKEN_KEY, accessToken, {
			path: '/',
			secure: true,
			sameSite: 'lax',
		});
	},

	getToken: (): string => cookies.get(TOKEN_KEY),

	clearToken: (): void => cookies.remove(TOKEN_KEY),
};

export default CookieService;
