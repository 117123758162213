import { Navigate, Route, Routes } from 'react-router-dom';

import AccountSettings from '../AccountSettings/AccountSettings';
import ActionItemsPage from '../ActionItemsPage/ActionItemsPage';
import ActionItemsSecondaryNav from '../ActionItemsPage/ActionItemsSecondaryNav/ActionItemsSecondaryNav';
import ChangePasswordPage from '../ChangePassword/ChangePasswordPage';
import CongratsPage from '../CongratsPage/CongratsPage';
import ContactUsPage from '../ContactUsPage/ContactUsPage';
import CreateNewTeamFormPage from '../CreateNewTeamFormPage/CreateNewTeamFormPage';
import NotFoundErrorPage from '../ErrorPage/NotFoundErrorPage';
import ServerErrorPage from '../ErrorPage/ServerErrorPage';
import FAQ from '../FAQ/FAQ';
import FeedbackPage from '../FeedbackPage/FeedbackPage';
import GoodbyePage from '../GoodbyePage/GoodbyePage';
import HistoricalResultsPage from '../HistoricalResultsPage/HistoricalResultsPage';
import HomePage from '../HomePage/HomePage';
import LandingPage from '../LandingPage/LandingPage';
import LoginFormPage from '../LoginFormPage/LoginFormPage';
import PromptsPage from '../PromptsPage/PromptsPage';
import RecoverTeamNamePage from '../RecoverTeamNamePage/RecoverTeamNamePage';
import ResetPasswordPage from '../ResetPasswordPage/ResetPasswordPage';
import SelectPromptsPage from '../SelectPromptsPage/SelectPromptsPage';
import SessionActionItemsPage from '../SessionActionItemsPage/SessionActionItemsPage';
import SessionResultsPage from '../SessionResultsPage/SessionResultsPage';
import SessionSecondaryNav from '../SessionSecondaryNav/SessionSecondaryNav';
import useAuthenticationContext from '../Utils/hooks/useAuthenticationContext';

import AuthenticatedRoute from './AuthenticatedRoute';
import {
	ACCOUNT_SETTINGS_PATH,
	ACTION_ITEMS_PATH,
	CHANGE_PASSWORD_PATH,
	CONTACT_US_PATH,
	CREATE_TEAM_PATH,
	FAQ_PATH,
	FEEDBACK_ROUTE,
	GOODBYE_PATH,
	HEALTH_CHECK_ENDED_PATH,
	HISTORICAL_RESULTS_PATH,
	HOME_PATH,
	LOGIN_PATH,
	PROMPTS_PATH,
	RECOVER_TEAM_NAME_PATH,
	RESET_PASSWORD_PATH,
	ROOT_PATH,
	SELECT_PROMPTS_PATH,
	SERVER_ERROR_PAGE_PATH,
} from './RouteConstants';

function RouteContents() {
	const { isAuthenticated } = useAuthenticationContext();

	const SessionActionItems = (
		<>
			<SessionSecondaryNav />
			<SessionActionItemsPage />
		</>
	);
	const SessionResults = (
		<>
			<SessionSecondaryNav />
			<SessionResultsPage />
		</>
	);

	const LoginPageIfAuthenticated = isAuthenticated ? (
		<Navigate to={HOME_PATH} />
	) : (
		<LoginFormPage />
	);
	return (
		<Routes>
			<Route path={ROOT_PATH} element={<LandingPage />} />
			<Route
				path={CREATE_TEAM_PATH}
				element={<CreateNewTeamFormPage />}
			/>
			<Route path={LOGIN_PATH} element={LoginPageIfAuthenticated}>
				<Route
					path={LOGIN_PATH + '/:teamName'}
					element={LoginPageIfAuthenticated}
				/>
			</Route>
			<Route path={RESET_PASSWORD_PATH} element={<ResetPasswordPage />} />
			<Route path={GOODBYE_PATH} element={<GoodbyePage />} />
			<Route path={CONTACT_US_PATH} element={<ContactUsPage />} />
			<Route
				path={FAQ_PATH}
				element={<FAQ title="Frequently Asked Questions" />}
			/>
			<Route path={HOME_PATH} element={<AuthenticatedRoute />}>
				<Route path={HOME_PATH} element={<HomePage />} />
			</Route>
			<Route path={SELECT_PROMPTS_PATH} element={<AuthenticatedRoute />}>
				<Route
					path={SELECT_PROMPTS_PATH + '/'}
					element={<SelectPromptsPage />}
				/>
			</Route>
			<Route
				path={HISTORICAL_RESULTS_PATH}
				element={<AuthenticatedRoute />}
			>
				<Route
					path={HISTORICAL_RESULTS_PATH + '/'}
					element={<HistoricalResultsPage />}
				/>
			</Route>
			<Route path={PROMPTS_PATH} element={<AuthenticatedRoute />}>
				<Route path={PROMPTS_PATH + '/'} element={<PromptsPage />} />
			</Route>
			<Route
				path={HISTORICAL_RESULTS_PATH + '/:sessionId'}
				element={<AuthenticatedRoute />}
			>
				<Route
					path={HISTORICAL_RESULTS_PATH + '/:sessionId'}
					element={SessionResults}
				/>
			</Route>
			<Route
				path={ACTION_ITEMS_PATH + '/:sessionId'}
				element={<AuthenticatedRoute />}
			>
				<Route
					path={ACTION_ITEMS_PATH + '/:sessionId'}
					element={SessionActionItems}
				/>
			</Route>
			<Route path={ACTION_ITEMS_PATH} element={<AuthenticatedRoute />}>
				<Route
					path={ACTION_ITEMS_PATH}
					element={
						<>
							<ActionItemsSecondaryNav />
							<ActionItemsPage />
						</>
					}
				/>
			</Route>
			<Route
				path={ACCOUNT_SETTINGS_PATH}
				element={<AuthenticatedRoute />}
			>
				<Route
					path={ACCOUNT_SETTINGS_PATH}
					element={<AccountSettings />}
				/>
			</Route>
			<Route path={HEALTH_CHECK_ENDED_PATH} element={<CongratsPage />} />
			<Route
				path={CHANGE_PASSWORD_PATH}
				element={<ChangePasswordPage />}
			/>
			<Route
				path={RECOVER_TEAM_NAME_PATH}
				element={<RecoverTeamNamePage />}
			/>
			<Route path={FEEDBACK_ROUTE} element={<FeedbackPage />} />
			<Route
				path={SERVER_ERROR_PAGE_PATH}
				element={<ServerErrorPage />}
			/>
			<Route path="*" element={<NotFoundErrorPage />} />
		</Routes>
	);
}

export { RouteContents };
