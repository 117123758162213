import {
	DefaultBadIcon,
	DefaultBadIconGray,
	DefaultEhIcon,
	DefaultEhIconGray,
	DefaultGoodIcon,
	DefaultGoodIconGray,
	DefaultGreatIcon,
	DefaultGreatIconGray,
	DefaultOkIcon,
	DefaultOkIconGray,
} from '../Assets/Emoticons/Default';
import {
	HalloweenBadIcon,
	HalloweenBadIconGray,
	HalloweenEhIcon,
	HalloweenEhIconGray,
	HalloweenGoodIcon,
	HalloweenGoodIconGray,
	HalloweenGreatIcon,
	HalloweenGreatIconGray,
	HalloweenOkIcon,
	HalloweenOkIconGray,
} from '../Assets/Emoticons/Halloween';
import DateService from '../Utils/DateService';

const HALLOWEEN_THEME = 'Halloween';
const DEFAULT_THEME = 'Default';

type EmoticonThemeTypes = typeof DEFAULT_THEME | typeof HALLOWEEN_THEME;

export const Emoticons = {
	[DEFAULT_THEME]: {
		BadIcon: DefaultBadIcon,
		BadIconGray: DefaultBadIconGray,
		EhIcon: DefaultEhIcon,
		EhIconGray: DefaultEhIconGray,
		OkIcon: DefaultOkIcon,
		OkIconGray: DefaultOkIconGray,
		GoodIcon: DefaultGoodIcon,
		GoodIconGray: DefaultGoodIconGray,
		GreatIcon: DefaultGreatIcon,
		GreatIconGray: DefaultGreatIconGray,
	},
	[HALLOWEEN_THEME]: {
		BadIcon: HalloweenBadIcon,
		BadIconGray: HalloweenBadIconGray,
		EhIcon: HalloweenEhIcon,
		EhIconGray: HalloweenEhIconGray,
		OkIcon: HalloweenOkIcon,
		OkIconGray: HalloweenOkIconGray,
		GoodIcon: HalloweenGoodIcon,
		GoodIconGray: HalloweenGoodIconGray,
		GreatIcon: HalloweenGreatIcon,
		GreatIconGray: HalloweenGreatIconGray,
	},
};

const getEmoticonsTheme = (): EmoticonThemeTypes => {
	const currentMonth: number = DateService.getMonth();

	const monthsWithThemes = new Map([[10, HALLOWEEN_THEME]]);

	if (monthsWithThemes.has(currentMonth)) {
		return monthsWithThemes.get(currentMonth) as EmoticonThemeTypes;
	}

	return DEFAULT_THEME;
};

const getEmoticonsByTheme = () => Emoticons[getEmoticonsTheme()];

export default getEmoticonsByTheme;
