import {FormEvent, useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';

import Input from '../Input/Input';
import RestService from '../RestService/RestService';
import {
	CREATE_TEAM_PATH,
	CREATE_TEAM_SUCCESS_CONTEXT,
	HOME_PATH,
	PASSWORD_CHANGED_CONTEXT,
	RESET_PASSWORD_PATH,
} from '../Routes/RouteConstants';
import {AccessTokenAtom} from '../State/AccessTokenAtom';

import './LoginFormPage.scss';

interface LoginData {
	name: string;
	password: string;
}

function LoginFormPage() {
	const [hasLoginError, setHasLoginError] = useState<boolean>(false);
	const [passwordError, setPasswordError] = useState<boolean>(false);
	const [nameError, setNameError] = useState<boolean>(false);
	const setAccessToken = useSetRecoilState(AccessTokenAtom);
	let userNameInputRef = useRef<HTMLInputElement>();
	let passwordInputRef = useRef<HTMLInputElement>();
	const {teamName} = useParams<{ teamName: string }>();

	const params = new URLSearchParams(useLocation().search);
	const navigate = useNavigate();

	useEffect(() => {
		if (userNameInputRef.current) {
			userNameInputRef.current.focus();
		}
	}, []);

	const context = params.get('context');

	const [loginData, setLoginData] = useState<LoginData>({
		name: teamName ? teamName : '',
		password: '',
	});

	const goToCreateNewTeamPage = () => {
		navigate(CREATE_TEAM_PATH);
	};

	const onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!hasLoginError) {
			RestService.login(loginData)
				.then((response) => {
					const {accessToken} = response.data;
					setAccessToken(accessToken);
					navigate(HOME_PATH);
				})
				.catch(() => {
					setHasLoginError(true);
				});
		}
	};
	const LoginGreeting = () => {
		switch (context) {
			case CREATE_TEAM_SUCCESS_CONTEXT:
				return (
					<p
						role="status"
						className="login-form__message"
						data-testid="loginForm__team-creation-success-message"
					>
						Your team has been created! You can now log in below.
					</p>
				);
			case PASSWORD_CHANGED_CONTEXT:
				return (
					<p
						role="status"
						className="login-form__message"
						data-testid="loginForm__team-creation-success-message"
					>
						Your password has been successfully changed. You can now
						log in below:
					</p>
				);
			default:
				return (
					<p
						className="login-form__message"
						data-testid="loginForm__welcome-message"
					>
						Do a deep dive on your team’s wins and challenges, and
						unearth topics that might not come up naturally in
						retro.
					</p>
				);
		}
	};

	const loginTitleText = () => {
		switch (context) {
			case CREATE_TEAM_SUCCESS_CONTEXT:
				return 'Success!';
			case PASSWORD_CHANGED_CONTEXT:
				return 'Success!';
			default:
				return 'Welcome to Vibez!';
		}
	};

	return (
		<form
			className="form-container login-form"
			data-testid="loginForm"
			onSubmit={onSubmit}
			onInvalid={(e) => {
				e.preventDefault();
				setPasswordError(!passwordInputRef.current?.validity.valid);
				setNameError(!userNameInputRef.current?.validity.valid);
			}}
		>
			<h1 data-testid="loginForm__title">{loginTitleText()}</h1>
			<LoginGreeting/>
			<Input
				label="Team Name"
				errorText={
					hasLoginError
						? 'Team name and password do not match.'
						: 'Team Name is required.'
				}
				valid={!hasLoginError && !nameError}
				required
				testId="loginForm__team-name"
				onChange={(name) => {
					setLoginData({
						...loginData,
						name,
					});
					setHasLoginError(false);
					setNameError(false);
				}}
				inputRef={userNameInputRef}
				value={teamName}
			/>
			<Input
				label="Password"
				type="password"
				errorText="Password is required."
				required
				valid={!passwordError}
				testId="loginForm__password"
				onChange={(password) => {
					setLoginData({
						...loginData,
						password,
					});
					setHasLoginError(false);
					setPasswordError(false);
				}}
				inputRef={passwordInputRef}
			/>
			<button
				type="submit"
				data-testid="loginForm__login-button"
				className="button__primary"
			>
				Log In
			</button>
			<button
				data-testid="loginForm__create-new-team-button"
				className="button__secondary login-form__create-new-team-button"
				onClick={goToCreateNewTeamPage}
			>
				Create a New Team
			</button>
			<div className="login-form__forgot-password-link-wrapper">
				<Link
					to={RESET_PASSWORD_PATH}
					className="login-form__forgot-password-link"
					data-testid="loginForm__forgot-password-link"
				>
					Help, we’ve lost our login info!
				</Link>
			</div>
		</form>
	);
}

export default LoginFormPage;
