import React from "react";
import { useNavigate } from 'react-router-dom';

import { CONTACT_US_PATH } from '../../Routes/RouteConstants';

import './ContactUsSection.scss';

function ContactUsSection(): React.ReactElement {
	const navigate = useNavigate();

	const goToContactUsPage = () => {
		navigate(CONTACT_US_PATH);
	};

	return (
		<section className="contact-us-section">
			<h1 className="contact-us-section__title">
				Have Questions or Feedback for Us?
			</h1>
			<button
				className="button__tertiary contact-us-section__button"
				onClick={goToContactUsPage}
			>
				Contact Us
			</button>
		</section>
	);
}

export default ContactUsSection;
