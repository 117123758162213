import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {ACTION_ITEMS_PATH} from '../Routes/RouteConstants';
import {FeedbackOriginAtom} from '../State/FeedbackOriginAtom';

import './CongratsPage.scss';

function CongratsPage(): React.ReactElement {
	const [feedbackIsFromEndOfSession, setFeedbackIsFromEndOfSession] =
		useRecoilState(FeedbackOriginAtom);

	useEffect(() => {
		return () => {
			setFeedbackIsFromEndOfSession(true);
		};
		// eslint-disable-next-line
	}, [feedbackIsFromEndOfSession]);

	const feedbackFromEndOfSession = () => {
		return (
			<>
				<h1 className="congrats-page__title">Congrats!</h1>
				<p className="congrats-page__description">
					Your team just completed a health check. Your action items
					can be found in the top menu. Remember to mark them
					“complete” when you’re finished!
				</p>
				<Link to={ACTION_ITEMS_PATH} className="button__primary">
					View Action Items
				</Link>
			</>
		);
	};

	const feedbackFromFeedbackButton = () => {
		return (
			<>
				<h1 className="congrats-page__title">Thank You!</h1>
				<p className="congrats-page__description">
					Thanks for giving us feedback! If you included your CDSID,
					we may reach out to you for more details. Have a great day!
				</p>
			</>
		);
	};

	return (
		<div
			className="congrats-page form-container"
			data-testid="congratsPage"
		>
			{feedbackIsFromEndOfSession
				? feedbackFromEndOfSession()
				: feedbackFromFeedbackButton()}
		</div>
	);
}

export default CongratsPage;
