import {useCallback, useState} from 'react';

import AccountDetailsSecondaryNav from './AccountDetailsSecondaryNav';
import PasswordUpdateTab from "./PasswordUpdateTab";
import TeamDetailsTab from "./TeamDetailsTab";

import './AccountSettings.scss';

function AccountSettings() {

	const [
		mobileDisplayTeamNameChangeDialog,
		setMobileDisplayTeamNameChangeDialog,
	] = useState(true);

	const [
		teamDetailsSubmissionSuccessful,
		setTeamDetailsSubmissionSuccessful,
	] = useState(false);

	function toggleSecondaryNav() {
		setMobileDisplayTeamNameChangeDialog(
			!mobileDisplayTeamNameChangeDialog
		);
	}

	const resetSuccessfulSubmission = useCallback( () => {
		setTeamDetailsSubmissionSuccessful(false);
	},[]);

	const onSuccessfulSubmission = useCallback( () => {
		setTeamDetailsSubmissionSuccessful(true);
	},[]);

	return (
		<div className="account-settings-container">
			<h1 className="account-settings-page__title">Account Settings</h1>
			<AccountDetailsSecondaryNav
				toggleFunction={toggleSecondaryNav}
				showAccountDetailsTabInMobile={mobileDisplayTeamNameChangeDialog}
			/>

			<div className="form-container account-settings__form-container">
				<TeamDetailsTab
					displayThis={mobileDisplayTeamNameChangeDialog}
					onSubmitSuccess={onSuccessfulSubmission}
					onChange={resetSuccessfulSubmission}
				/>
				<PasswordUpdateTab
					displayThis={!mobileDisplayTeamNameChangeDialog}
					onSubmitSuccess={onSuccessfulSubmission}
					onChange={resetSuccessfulSubmission}
				/>
			</div>

			{teamDetailsSubmissionSuccessful && (
				<div className="account-settings__changes-confirmed" aria-live={"polite"}>
					Your changes have been saved!
				</div>
			)}
		</div>
	);
}

export default AccountSettings;
