import { atom, RecoilState, selectorFamily } from 'recoil';

import { ActionItem } from '../RestService/Models/ActionItem';

import { RecentlyMarkedDoneActionItemIdsAtom } from './RecentlyMarkedDoneActionItemIdsAtom';

export const ActionItemsAtom = atom<Array<ActionItem>>({
	key: 'actionItemsAtom',
	default: [],
});

export const UpdateActionItemsSelector: (
	actionItemId: string | undefined
) => RecoilState<ActionItem | undefined> = selectorFamily({
	key: 'UpdateActionItemSelector',
	get:
		(actionItemId: string | undefined) =>
		({ get }): ActionItem | undefined => {
			return get(ActionItemsAtom).find(
				(item) => item.id === actionItemId
			);
		},
	set:
		(actionItemId: string | undefined) =>
		({ set, get }, newActionItem) => {
			newActionItem = newActionItem as ActionItem;

			storeRecentlyDoneActionItemIds(newActionItem, set, actionItemId);

			let updatingAnswer = false;
			let updatedActionItems = get(ActionItemsAtom).map((item) => {
				if (item.id === actionItemId) {
					updatingAnswer = true;
					return newActionItem;
				} else {
					return item;
				}
			}) as Array<ActionItem>;

			if (!updatingAnswer) {
				updatedActionItems = [
					...updatedActionItems,
					newActionItem,
				];
			}
			set(ActionItemsAtom, sortActionItems(updatedActionItems));
		},
});

export const sortActionItems = (actionItems: Array<ActionItem>) => {
	return [...actionItems]
		.sort((item1, item2) => {
			return (
				new Date(item2.dateCreated).getTime() -
				new Date(item1.dateCreated).getTime()
			);
		})
		.sort((item1, item2) => {
			return Number(item1.isDone) - Number(item2.isDone);
		});
};

function storeRecentlyDoneActionItemIds(
	newActionItem: ActionItem,
	set: any,
	actionItemId: string | undefined
) {
	const actionItemIsComplete = newActionItem?.isDone === true;
	if (actionItemIsComplete) {
		set(
			RecentlyMarkedDoneActionItemIdsAtom,
			(currentState: Array<string>) => [...currentState, actionItemId!]
		);
	} else {
		set(
			RecentlyMarkedDoneActionItemIdsAtom,
			(currentState: Array<string>) => {
				return [...currentState].filter((id) => id !== actionItemId);
			}
		);
	}
}
