import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
	ACTION_ITEMS_PATH,
	FEEDBACK_ROUTE,
	HISTORICAL_RESULTS_PATH,
	PROMPTS_PATH,
	SELECT_PROMPTS_PATH,
} from '../../Routes/RouteConstants';

export type ColorSchemeType = 'dark' | 'light';

function useColorScheme(): ColorSchemeType {
	const location = useLocation();
	const [colorScheme, setColorScheme] = useState<ColorSchemeType>('dark');

	useEffect(() => {
		const sessionResultsPathRegex = new RegExp(
			HISTORICAL_RESULTS_PATH + '/'
		);
		const sessionActionItemsPathRegex = new RegExp(
			ACTION_ITEMS_PATH + '/.*'
		);
		const lightModeRoutes = [
			PROMPTS_PATH,
			SELECT_PROMPTS_PATH,
			FEEDBACK_ROUTE,
			sessionResultsPathRegex,
			sessionActionItemsPathRegex,
		];
		const isLightColorTheme = lightModeRoutes.reduce(
			(a, b) => a || RegExp(b).test(location.pathname),
			false
		);
		setColorScheme(isLightColorTheme ? 'light' : 'dark');
	}, [location.pathname, setColorScheme]);

	return colorScheme;
}

export default useColorScheme;
