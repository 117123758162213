import './AccountDetailsSecondaryNav.scss';

interface Props {
	toggleFunction: Function;
	showAccountDetailsTabInMobile: boolean;
}

function AccountDetailsSecondaryNav({toggleFunction, showAccountDetailsTabInMobile}: Props) {
	function changePasswordClicked() {
		if (showAccountDetailsTabInMobile) {
			toggleFunction();
		}
	}

	function teamDetailsClicked() {
		if (!showAccountDetailsTabInMobile) {
			toggleFunction();
		}
	}

	return (
		<div className="account-details__secondary-nav-container">
			<button
				data-testid="accountDetails__secondary-nav-team-details-link"
				onClick={teamDetailsClicked}
				className={`account-details__toggle-link  ${
					showAccountDetailsTabInMobile ? 'is-active' : ''
				}`}
				aria-label="edit team name or email"
			>
				Team Details
			</button>
			<button
				data-testid="accountDetails__secondary-nav-reset-password-link"
				onClick={changePasswordClicked}
				className={`account-details__toggle-link  ${
					showAccountDetailsTabInMobile ? '' : 'is-active'
				}`}
				aria-label="change team password"
			>
				Password
			</button>
		</div>
	);
}

export default AccountDetailsSecondaryNav;
