import React from "react";

import { ReactComponent as AddPromptIcon } from '../Assets/Icons/prompts-add.svg';

import './AddActionItemButton.scss';

interface Props {
	className?: string;
	onClickHandler(): any;
}

function AddActionItemButton({
	className,
	onClickHandler,
}: Props): React.ReactElement {
	return (
		<div className="add-action-item-button__background">
			<button
				className={`add-action-item-button ${className || ''}`}
				data-testid="addActionItemButton__add-button"
				onClick={onClickHandler}
				aria-label="Add Action Item"
			>
				<AddPromptIcon
					role="presentation"
					className="add-action-item-button__icon"
				/>
				Add Action Item
			</button>
		</div>
	);
}

export default AddActionItemButton;
