export const CREATE_TEAM_SUCCESS_CONTEXT = 'createTeam';
export const PASSWORD_CHANGED_CONTEXT = 'passwordChanged';

export const CREATE_TEAM_PATH = '/create';
export const CREATE_TEAM_SUCCESS_PATH =
	'/login?context=' + CREATE_TEAM_SUCCESS_CONTEXT;
export const LOGIN_PATH = '/login';
export const RESET_PASSWORD_PATH = '/reset-password';
export const CHANGE_PASSWORD_PATH = '/change-password';
export const RECOVER_TEAM_NAME_PATH = '/recover-team-name';
export const SELECT_PROMPTS_PATH = '/select-prompts';
export const PROMPTS_PATH = '/prompts';
export const ROOT_PATH = '/';
export const HOME_PATH = '/home';
export const HISTORICAL_RESULTS_PATH = '/results';
export const sessionResultPath = (sessionId:string) =>
	`${HISTORICAL_RESULTS_PATH}/${sessionId}`;
export const ACTION_ITEMS_PATH = '/action-items';
export const actionItemsPath = (sessionId:string) =>
	`${ACTION_ITEMS_PATH}/${sessionId}`;
export const GOODBYE_PATH = '/goodbye';
export const FEEDBACK_ROUTE = '/feedback';
export const CONTACT_US_PATH = '/contact-us';
export const FAQ_PATH = '/frequently-asked-questions';
export const HEALTH_CHECK_ENDED_PATH = '/health-check-ended';
export const ACCOUNT_SETTINGS_PATH = '/account-settings';
export const SERVER_ERROR_PAGE_PATH = '/server-error';
