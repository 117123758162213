import React from "react";
import { useSetRecoilState } from 'recoil';

import { ReactComponent as UncheckedDoneIcon } from '../../Assets/Iconify-Icons/bx__checkbox.svg';
import { ReactComponent as CheckedDoneIcon } from '../../Assets/Iconify-Icons/bx__checkbox-checked.svg';
import { ActionItem } from '../../RestService/Models/ActionItem';
import { EditActionItemRequest } from '../../RestService/Models/EditActionItemRequest';
import RestService from '../../RestService/RestService';
import { UpdateActionItemsSelector } from '../../State/ActionItemsAtom';

interface Props {
	actionItem: ActionItem;
	disabled?: boolean;
}

function ActionItemCardDoneButton({ actionItem }: Props): React.ReactElement {
	const setActionItem = useSetRecoilState(
		UpdateActionItemsSelector(actionItem?.id)
	);
	const { isDone } = actionItem;

	const updateIsDoneFlagForActionItem = (updatedIsDone: boolean) => {
		const updatedActionItem: EditActionItemRequest = {
			action: actionItem.action,
			assignedTo: actionItem.assignedTo,
			isDone: updatedIsDone,
		};
		RestService.editActionItem(actionItem.id, updatedActionItem)
			.then(setActionItem)
			.catch(console.error);
	};

	return (
		<button
			className={`action-item-card__controls-button ${
				isDone ? 'lightened-border' : ''
			}`}
			data-testid="actionItemCard__done-button"
			aria-label={`Mark "${actionItem.action}" action item card as ${
				isDone ? 'not ' : ''
			} done`}
			onClick={() => updateIsDoneFlagForActionItem(!isDone)}
		>
			{isDone ? (
				<CheckedDoneIcon
					className="action-item-card__controls-icon action-item-card__done-icon"
					role="presentation"
				/>
			) : (
				<UncheckedDoneIcon
					className="action-item-card__controls-icon action-item-card__done-icon"
					role="presentation"
				/>
			)}
			Done
		</button>
	);
}

export default ActionItemCardDoneButton;
