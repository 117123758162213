import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as CloseIcon } from "../../Assets/Icons/prompts-delete-x.svg";
import EmoticonImage from '../../Emoticon/EmoticonImage';
import { emoticonMap } from '../../Emoticon/EmoticonMap';
import { ModalContentsAtom } from "../../State/ModalContentsAtom";
import { SessionAtom } from '../../State/SessionAtom';
import { ShowResponsesAtom } from '../../State/ShowResponsesAtom';
import { AnswerForPromptSelector } from '../../State/UserAnswersAtom';
import SessionResultCard from '../SessionResultCard/SessionResultCard';
import { SinglePromptResultsProps } from '../SinglePromptResultsProps';

import ChatWindow from "./ChatWindow/ChatWindow";
import SessionResultsCardSecondaryNav from "./SessionResultsCardSecondaryNav/SessionResultsCardSecondaryNav";

import './ExpandedSinglePromptResult.scss';

type GroupedAnswerInfo = {
	numVotes: number;
	comments: Array<string>;
};

type GroupedAnswers = {
	[key: string]: GroupedAnswerInfo;
};

function ExpandedSinglePromptResult({
										prompt,
										answersForPrompt,
										dataTestId,
										onClick,
									}: SinglePromptResultsProps) {
	const showResponses = useRecoilValue(ShowResponsesAtom);
	const session = useRecoilValue(SessionAtom);
	const setModal = useSetRecoilState(ModalContentsAtom);
	const promptId = prompt.id;
	const myAnswerForPrompt = useRecoilValue(AnswerForPromptSelector(promptId));
	let myVoteWasFound = false;

	const thisIsMyVote = (vibeValue: number): boolean => {
		const myVoteForPrompt = myAnswerForPrompt?.vibeValue;
		const vibeValuesMatch = myVoteForPrompt === vibeValue;
		const sessionIsActive = !session?.dateCompleted;

		if (vibeValuesMatch && !myVoteWasFound && sessionIsActive) {
			myVoteWasFound = true;
			return myVoteWasFound;
		}
		return false;
	};

	const groupAnswersTogether = (): GroupedAnswers => {
		const groupedAnswers: GroupedAnswers = {};
		Object.keys(emoticonMap).forEach((emoticonValue) => {
			const vibeValue = parseInt(emoticonValue);
			const answersWithThisEmoticonValue = answersForPrompt.filter(
				(answer) => answer.vibeValue === vibeValue
			);
			groupedAnswers[emoticonValue] = {
				numVotes: answersWithThisEmoticonValue.length,
				comments: answersWithThisEmoticonValue
					.filter((singleAnswer) => singleAnswer.comment)
					.map((singleAnswer) => singleAnswer.comment!!),
			};
		});

		return groupedAnswers;
	};
	const answersGroupedTogether = groupAnswersTogether();

	const showVotes = (
		answersForEmoticonValue: GroupedAnswerInfo,
		vibeValue: number,
		emoticonIndex: number
	) => {
		const rangeOfVotes: Array<number> = [
			...Array(answersForEmoticonValue.numVotes),
		];
		return (
			<div
				className="expanded-single-prompt-result__votes"
				role="region"
				aria-label={`${answersForEmoticonValue.numVotes} users voted ${emoticonMap[vibeValue]}`}
			>
				{rangeOfVotes.map((_, answerIndex) => {
					return (
						<React.Fragment
							key={`vote_for_vibe_value_${emoticonIndex}_${answerIndex}`}
						>
							<EmoticonImage
								value={vibeValue}
								grayedOut={false}
								className={
									showResponses && thisIsMyVote(vibeValue)
										? 'green-border'
										: undefined
								}
							/>
						</React.Fragment>
					);
				})}
			</div>
		);
	};

	const showComments = (
		answersForEmoticonValue: GroupedAnswerInfo,
		emoticonIndex: number
	) =>
		answersForEmoticonValue.comments.length > 0 &&
		answersForEmoticonValue.comments.map((comment, answerIndex) => (
			<p
				className="expanded-single-prompt-result__comments"
				data-testid="expandedSinglePromptResult__comments"
				key={`answer_comment_${emoticonIndex}_${answerIndex}`}
				aria-label={`commented ${comment}`}
			>
				{comment}
			</p>
		));

	const [
		mobileDisplayChatModal,
		setMobileDisplayChatModal,
	] = useState(false);

	function toggleSecondaryNav() {
		setMobileDisplayChatModal(
			!mobileDisplayChatModal
		);
	}

	return (

		<div className="expanded-single-prompt-result">
			<button
				className="expanded-single-prompt-result__close-button"
				data-testid="expandedSinglePromptResult__close-button"
				aria-label={`Close ${prompt.category} prompt: ${prompt.promptText}`}
				onClick={() => {
					setModal(null);
				}}
			>
				<CloseIcon
					role="presentation"
					className="expanded-single-prompt-result__close-button__icon"
				/>
			</button>
			<div className="expanded-single-prompt-result__nav-container">
				<SessionResultsCardSecondaryNav
					toggleFunction={toggleSecondaryNav}
					showSessionResultsCardTeamChatOnMobile={mobileDisplayChatModal}
				/></div>
			<div className="expanded-single-prompt-result__content"
				 data-testid="expandedSinglePromptResult__content"
			>
				<SessionResultCard
					prompt={prompt}
					dataTestId={dataTestId}
					commentsButtonText="Close Window"
					onClick={onClick}
					ariaTitleId="modal_label"
					isExpanded
					className={mobileDisplayChatModal ? "" : "expanded-single-prompt-result__show-mobile-tab-content"}
				>

					<div className="expanded-single-prompt-result__votes-and-comments">

						{Object.keys(answersGroupedTogether).map(
							(emoticonValue, emoticonIndex) => {
								const answersForEmoticonValue =
									answersGroupedTogether[emoticonValue];
								const vibeValue = parseInt(emoticonValue);

								return (
									answersForEmoticonValue.numVotes > 0 && (
										<div
											className="expanded-single-prompt-result__votes-and-comments-group"
											data-testid="expandedSinglePromptResult__votesAndCommentsGroup"
											key={`answer_group_${emoticonIndex}`}
											role="region"
											aria-label={`Votes and comments for ${emoticonMap[vibeValue]}`}
										>
											{showVotes(
												answersForEmoticonValue,
												vibeValue,
												emoticonIndex
											)}
											{showComments(
												answersForEmoticonValue,
												emoticonIndex
											)}
										</div>
									)
								);
							}
						)}
					</div>
				</SessionResultCard>
				<ChatWindow
					className={mobileDisplayChatModal ? "expanded-single-prompt-result__show-mobile-tab-content" : ""}/>
			</div>
		</div>
	)
		;
}

export default ExpandedSinglePromptResult;
