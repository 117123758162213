import { useRef } from 'react';
import FocusTrap from 'focus-trap-react';

import useOnClickOutside from '../../Utils/hooks/useOnClickOutside';

import './PromptsConfirmDeleteOverlay.scss';

interface Props {
	onYesClicked: () => void;
	onNoClicked: () => void;
}

function PromptsConfirmDeleteOverlay({ onYesClicked, onNoClicked }: Props) {
	const ref = useRef(null);

	useOnClickOutside(ref, onNoClicked);

	return (
		<FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
			<div
				ref={ref}
				data-testid="promptsConfirmDeleteOverlay"
				className="prompts-confirm-delete-overlay__container form-container"
			>
				<h1 className="prompts-confirm-delete-overlay__text">
					Delete this custom prompt?
				</h1>
				<div className="prompts-confirm-delete-overlay__button-container">
					<button
						onClick={onNoClicked}
						data-testid="promptsConfirmDelete__prompt-card-delete-no-button"
						className="button__primary"
					>
						No
					</button>
					<button
						onClick={onYesClicked}
						data-testid="promptsConfirmDelete__prompt-card-delete-yes-button"
						className="button__secondary"
					>
						Yes
					</button>
				</div>
			</div>
		</FocusTrap>
	);
}

export default PromptsConfirmDeleteOverlay;
