import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { Session } from '../../RestService/Models/Session';
import RestService from '../../RestService/RestService';
import { SessionAtom } from '../../State/SessionAtom';

function useGetSession(): Session | null {
	const { sessionId } = useParams<{ sessionId: string }>();
	const [session, setSession] = useRecoilState(SessionAtom);

	useEffect(() => {
		if (sessionId) {
			RestService.getSessionById(sessionId)
				.then(setSession)
				.catch(console.error);
		} else {
			RestService.getCurrentSession()
				.then(setSession)
				.catch(console.error);
		}
	}, [sessionId, setSession]);

	return session;
}

export default useGetSession;
