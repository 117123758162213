import {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useSetRecoilState} from 'recoil';

import BaseSecondaryNav, {NavItem,} from '../../BaseSecondaryNav/BaseSecondaryNav';
import RestService from '../../RestService/RestService';
import {ActionItemsAtom} from '../../State/ActionItemsAtom';

import './ActionItemsSecondaryNav.scss';

function ActionItemsSecondaryNav() {
	const setActionItems = useSetRecoilState(ActionItemsAtom);
	const searchParams = useLocation().search;
	const displayCompletedActionItems =
		new URLSearchParams(searchParams).get('completed') === 'true';

	useEffect(() => {
		RestService.getAllActionItemsForAllSessions()
			.then(setActionItems)
			.catch(console.error);
	}, [setActionItems]);

	const navItems: Array<NavItem> = [
		{
			path: '/action-items',
			testId: 'actionItemsSecondaryNav__to-do-link',
			label: 'To Do',
			isActive: !displayCompletedActionItems
		},
		{
			path: '/action-items?completed=true',
			testId: 'actionItemsSecondaryNav__done-link',
			label: `Done`,
			isActive: displayCompletedActionItems
		},
	];
	return (
		<BaseSecondaryNav
			navItems={navItems}
			className="action-items-secondary-nav"
		/>
	);
}

export default ActionItemsSecondaryNav;
