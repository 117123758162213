import { atom } from 'recoil';

import CookieService from '../CookieService/CookieService';

export const AccessTokenAtom = atom<string | null>({
	key: 'accessTokenAtom',
	default: CookieService.getToken(),
	effects_UNSTABLE: [
		({ onSet }) => {
			onSet((newToken) => {
				if (newToken) {
					CookieService.setToken(newToken);
				} else {
					CookieService.clearToken();
				}
			});
		},
	],
});
