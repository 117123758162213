import React from "react";

import {ReactComponent as ContactIcon} from '../Assets/Iconify-Icons/fa__solid__comment-dots.svg';

import './ContactUsPage.scss';

function ContactUsPage(): React.ReactElement {

	return (
		<div className="contact-us__container">
			<h1 className="contact-us__title">Contact Us</h1>
			<div className="form-container">
				<p className="contact-us__info">
					Have questions or feedback for us? Reach out!
				</p>
				<a
					href="https://engage.cloud.microsoft/main/org/ford.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxODU5MjM2NDEzNDQifQ/all"
					className="button__secondary contact-us__link"
					target="_blank"
					rel="noreferrer">
					<ContactIcon
						className="contact-us__contact-icon"
						role="presentation"
					/>
					Contact Support
				</a>
			</div>
		</div>
	)
		;
}

export default ContactUsPage;
