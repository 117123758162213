import { useEffect } from 'react';
import { decodeToken } from 'react-jwt';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ACCOUNT_SETTINGS_PATH, ROOT_PATH } from '../Routes/RouteConstants';
import { MobileMenuIsOpenAtom } from '../State/MobileMenuIsOpenAtom';
import { TeamAtom } from '../State/TeamAtom';
import VibezToken from '../Types/VibezToken';
import useAuthenticationContext from '../Utils/hooks/useAuthenticationContext';

import Navigation from './Navigation/Navigation';

import './Header.scss';

function Header() {
	const mobileMenuIsOpen = useRecoilValue(MobileMenuIsOpenAtom);
	const [teamData, setTeamData] = useRecoilState(TeamAtom);
	const {accessToken} = useAuthenticationContext();

	useEffect(() => {
		if (accessToken) {
			const decodedToken =
				(decodeToken(accessToken) as VibezToken) ?? null;
			if (decodedToken) {
				setTeamData({
					name: decodedToken.sub,
					id: decodedToken['team-id'],
					email: decodedToken['email'],
				});
			}
		}
	}, [accessToken, setTeamData]);

	return (
		<header
			className={`vibez-header ${
				mobileMenuIsOpen ? 'mobile-menu--open' : ''
			}`}
			data-testid="vibezHeader"
		>
			<div className="vibez-header__container">
				<div className="vibez-header__title-and-nav">
					<Link
						to={ROOT_PATH}
						data-testid="vibezHeader__logo-link"
						className="vibez-header__logo-link"
					>
						Vibez
					</Link>
					<Navigation/>
				</div>
				{accessToken && (
					<div
						className="vibez-header__team-name-container"
						data-testid="vibezHeader__team-name-container"
					>
						<div className="vibez-header__team-name">
							{teamData.name && (
								<Link
									aria-label={`${teamData.name} - Click to edit team`}
									to={ACCOUNT_SETTINGS_PATH}
									data-testid="vibezHeader__team-name-link"
									className="vibez-header__team-name-link"
								>
									{' '}
									{teamData.name}{' '}
								</Link>
							)}
						</div>
					</div>
				)}
			</div>
		</header>
	);
}

export default Header;
