import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const DateService = {
	getFormattedDate: (
		dateString: string | Date,
		formatString: string
	): string => {
		return dayjs.utc(dateString).tz(dayjs.tz.guess()).format(formatString);
	},
	getMonth: (): number => dayjs().month() + 1,
};

export default DateService;
