import { ReactComponent as GreatIcon } from '../../Assets/Emoticons/Default/default_5_great.svg';

import './WhatIsAHealthCheckSection.scss';

function WhatIsAHealthCheckSection() {
	return (
		<section className="what-is-a-health-check-section">
			<div className="what-is-a-health-check-section__content">
				<h1 className="what-is-a-health-check-section__title">
					What is a health check?
				</h1>
				<p className="what-is-a-health-check-section__description">
					A health check helps your team assess how well you’re
					working together. Unlike a retro, which may facilitate
					discussion around how a recent sprint went, a health check
					focuses on broader issues and helps gauge whether team
					members feel aligned overall. We encourage you to talk about
					what’s not working as well as to celebrate what makes your
					team shine!{' '}
					<GreatIcon
						role="presentation"
						className="emoticon__image"
					/>
				</p>
			</div>
		</section>
	);
}

export default WhatIsAHealthCheckSection;
