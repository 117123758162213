import React from "react";
import { useRecoilState } from 'recoil';

import { ReactComponent as UncheckedDoneIcon } from '../../Assets/Iconify-Icons/bx__checkbox.svg';
import { ReactComponent as CheckedDoneIcon } from '../../Assets/Iconify-Icons/bx__checkbox-checked.svg';
import { ShowResponsesAtom } from '../../State/ShowResponsesAtom';

import './VotesToggle.scss';

function VotesToggle(): React.ReactElement {
	const [showResponses, setShowResponses] = useRecoilState(ShowResponsesAtom);

	return (
		<button
			className={`session-results__banner-toggle-show-responses ${
				showResponses ? 'checked' : 'unchecked'
			}`}
			data-testid="sessionResults__votes-toggle"
			onClick={() => setShowResponses(!showResponses)}
			role="checkbox"
			aria-checked={showResponses}
		>
			{showResponses ? (
				<CheckedDoneIcon
					role="presentation"
					className="checkbox-icon"
				/>
			) : (
				<UncheckedDoneIcon
					role="presentation"
					className="checkbox-icon"
				/>
			)}
			Highlight My Votes
		</button>
	);
}

export default VotesToggle;
