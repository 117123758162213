import { RefObject, useCallback, useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent | KeyboardEvent;

// Copied from https://usehooks-typescript.com/react-hook/use-on-click-outside and modified
function useOnClickOutside<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>,
	handler: (event: AnyEvent) => void,
	options?: { disable?: boolean }
) {
	const listener = useCallback(
		(event: AnyEvent) => {
			const element = ref?.current;
			const clickingInsideRef =
				!element || element.contains(event.target as Node);
			if (clickingInsideRef) return;

			if (event.type === 'touchstart') event.preventDefault();

			handler(event);
		},
		[handler, ref]
	);

	const onKeyDownListener = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') handler(event);
		},
		[handler]
	);

	const removeListeners = useCallback(() => {
		document.removeEventListener(`mousedown`, listener);
		document.removeEventListener(`touchstart`, listener);
		document.removeEventListener(`keydown`, onKeyDownListener);
	}, [listener, onKeyDownListener]);

	useEffect(() => {
		if (options?.disable) {
			removeListeners();
		} else {
			document.addEventListener(`mousedown`, listener);
			document.addEventListener(`touchstart`, listener, {
				passive: false,
			});
			document.addEventListener(`keydown`, onKeyDownListener);
		}

		return removeListeners;
	}, [
		options?.disable,
		ref,
		handler,
		removeListeners,
		listener,
		onKeyDownListener,
	]);
}

export default useOnClickOutside;
