import {useNavigate} from 'react-router-dom';
import {IMessage} from '@stomp/stompjs';
import {useSetRecoilState} from 'recoil';

import {SingleAnonymousAnswerForPrompt} from '../../RestService/Models/SingleAnonymousAnswerForPrompt';
import {FEEDBACK_ROUTE, HEALTH_CHECK_ENDED_PATH,} from '../../Routes/RouteConstants';
import {ChatAtom} from "../../State/ChatAtom";
import {TeamAnswerSelector} from '../../State/TeamAnswersAtom';
import {AllSubmittedAnswers} from '../../Types/AllSubmittedAnswers';

type MessageType = 'ANSWER_SUBMITTED' | 'GO_TO_FEEDBACK' | 'GO_TO_CONGRATS' | 'CHAT_MESSAGE';

interface IncomingMessage {
	teamId: string;
	type: MessageType;
	data: string;
}

interface WebsocketCallback {
	websocketMessageHandler: Function;
}

function useWebSocketMessageHandler(): WebsocketCallback {
	const setTeamAnswers = useSetRecoilState(TeamAnswerSelector);
	const setChatMessages = useSetRecoilState(ChatAtom);
	let navigate = useNavigate();

	const websocketMessageHandler = ({body}: IMessage) => {
		const incomingMessage: IncomingMessage = JSON.parse(body);

		switch (incomingMessage.type) {
			case 'ANSWER_SUBMITTED': {
				const newAnswers = JSON.parse(
					incomingMessage.data
				) as Array<SingleAnonymousAnswerForPrompt>;
				const newSubmittedAnswers: AllSubmittedAnswers = {};

				newAnswers.forEach((answer) => {
					if (!newSubmittedAnswers[answer.promptId]) {
						newSubmittedAnswers[answer.promptId] = [];
					}
					newSubmittedAnswers[answer.promptId].push(answer);
				});

				setTeamAnswers(newSubmittedAnswers);
				break;
			}
			case 'GO_TO_FEEDBACK': {
				navigate(FEEDBACK_ROUTE);
				break;
			}
			case 'GO_TO_CONGRATS': {
				navigate(HEALTH_CHECK_ENDED_PATH);
				break;
			}
			case 'CHAT_MESSAGE': {
				setChatMessages((chatMessages: Array<string>) => [...chatMessages, JSON.parse(incomingMessage.data)]);
				break;
			}
			default: {
			}
		}
	};

	return {websocketMessageHandler};
}

export default useWebSocketMessageHandler;
