import React from "react";
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ModalContentsAtom } from '../State/ModalContentsAtom';
import { SessionAtom } from '../State/SessionAtom';

import ConfirmEndSessionModal from './ConfirmEndSessionModal/ConfirmEndSessionModal';

import './EndSessionButton.scss';

function EndSessionButton(): React.ReactElement {
	const session = useRecoilValue(SessionAtom);
	const sessionIsActive = !session?.dateCompleted;
	const setModalContents = useSetRecoilState(ModalContentsAtom);

	const onClick = () => {
		setModalContents(<ConfirmEndSessionModal/>);
	};

	return sessionIsActive ? (
		<button
			className="end-session-button button__secondary"
			data-testid="endSessionButton"
			onClick={onClick}
			aria-label="Archive Health Check"
		>
			Archive Health Check
		</button>
	) : (
		<></>
	);
}

export default EndSessionButton;
