import { FormEvent, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Input from '../Input/Input';
import RestService from '../RestService/RestService';
import { AccessTokenAtom } from '../State/AccessTokenAtom';
import { TeamAtom } from '../State/TeamAtom';

interface Props {
	displayThis: boolean;
	onSubmitSuccess: Function;
	onChange: Function;
}

function TeamDetailsTab({ displayThis, onSubmitSuccess, onChange }: Props) {
	const setAccessToken = useSetRecoilState(AccessTokenAtom);
	const team = useRecoilValue(TeamAtom);

	const teamDetailsNameRef = useRef<HTMLInputElement | null>(null);
	const teamDetailsEmailRef = useRef<HTMLInputElement | null>(null);
	const teamDetailsVerifyEmailRef = useRef<HTMLInputElement | null>(null);

	const [teamDetailsTeamName, setTeamDetailsTeamName] = useState<string>(
		team.name
	);
	const [teamDetailsEmail, setTeamDetailsEmail] = useState<string>(
		team.email
	);
	const [teamDetailsVerifyEmail, setTeamDetailsVerifyEmail] =
		useState<string>('');

	const [teamNameValid, setTeamNameValid] = useState<boolean>(true);
	const [emailValid, setEmailValid] = useState<boolean>(true);
	const [verifyEmailValid, setVerifyEmailValid] = useState<boolean>(true);

	useEffect(() => {
		onChange();
	}, [
		teamDetailsTeamName,
		teamDetailsEmail,
		teamDetailsVerifyEmail,
		onChange,
	]);

	function validateTeamName() {
		setTeamNameValid(teamDetailsNameRef.current?.validity.valid ?? false);
		return teamDetailsNameRef.current?.validity.valid;
	}

	function validateEmail() {
		setEmailValid(teamDetailsEmailRef.current?.value.includes('ford.com') ?? false);
		return teamDetailsEmailRef.current?.value.includes('@ford.com');
	}

	function validateEmailsMatch():boolean {
		let isValid: boolean = !!teamDetailsVerifyEmail && teamDetailsEmail === teamDetailsVerifyEmail;
		setVerifyEmailValid(isValid);
		return isValid;
	}

	function validateTeamDetails() {
		let teamNameExists = validateTeamName();
		let emailExists = validateEmail();
		let emailsMatch = validateEmailsMatch();
		return teamNameExists && emailExists && emailsMatch;
	}

	function onTeamDetailsSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		if (validateTeamDetails()) {
			RestService.changeTeamDetails(
				teamDetailsEmail,
				teamDetailsTeamName
			).then((result) => {
				if (result.status === 200) {
					onSubmitSuccess();
					setAccessToken(result.data.accessToken);
					teamDetailsNameRef.current = null;
					teamDetailsEmailRef.current = null;
					teamDetailsVerifyEmailRef.current = null;
				}
			});
		}
	}

	return (
		<div
			className={`account-settings__team-details-tab account-settings__mobile-tab-content ${
				displayThis ? 'account-settings__show-mobile-tab-content' : ''
			}`}
		>
			<h2 className="account-settings__tab-heading">Team Details</h2>
			<form
				onSubmit={onTeamDetailsSubmit}
				onInvalid={(error) => {
					error.preventDefault();
					validateTeamName();
					validateEmail();
					validateEmailsMatch();
				}}
			>
				<Input
					type="text"
					label="Team Name"
					errorText="Please Enter new team name."
					onChange={(teamName) => {
						setTeamDetailsTeamName(teamName);
						setTeamNameValid(true);
					}}
					required
					valid={teamNameValid}
					value={team.name}
					testId="accountSettings__team-name-input"
					inputRef={teamDetailsNameRef}
				/>
				<Input
					type="email"
					label="Email Address"
					errorText="Please specify a Ford email account."
					onChange={(email) => {
						setTeamDetailsEmail(email);
						setEmailValid(true);
					}}
					required
					valid={emailValid}
					value={team.email}
					testId="accountSettings__email-input"
					inputRef={teamDetailsEmailRef}
				/>
				<Input
					type="email"
					label="Confirm Email Address"
					errorText="Please verify email to use for this account."
					onChange={(email) => {
						setTeamDetailsVerifyEmail(email);
						setVerifyEmailValid(true);
					}}
					required
					valid={verifyEmailValid}
					testId="accountSettings__verify-email-input"
					inputRef={teamDetailsVerifyEmailRef}
				/>
				<button
					className="button__primary"
					data-testid="accountSettings__submit-button"
				>
					Save Changes
				</button>
			</form>
		</div>
	);
}

export default TeamDetailsTab;
