import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import RestService from '../../RestService/RestService';
import { ModalContentsAtom } from '../../State/ModalContentsAtom';

import './DeleteSessionModal.scss';

interface Props {
	sessionId: string;
}

interface FormElements extends HTMLFormControlsCollection {
	confirm: HTMLInputElement;
}

interface DeleteSessionForm extends HTMLFormElement {
	readonly elements: FormElements;
}

function DeleteSessionModal({ sessionId }: Props) {
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const navigate = useNavigate();

	function deleteSession(event: FormEvent<DeleteSessionForm>) {
		event.preventDefault();
		const form = event.currentTarget.elements;
		const verifiedInput = form.confirm.value;
		if (verifiedInput.toLowerCase() === 'delete') {
			RestService.deleteSession(sessionId)
				.catch(console.error)
				.finally(() => {
					setModalContents(null);
					navigate('/');
					navigate('/results');
				});
		}
	}

	return (
		<form
			className="form-container delete-session-modal"
			onSubmit={deleteSession}
			data-testid="deleteSessionModal__form"
		>
			<p className="delete-session-modal__title">
				Are you sure you want to delete this health check?
				<br />
				This cannot be undone.
			</p>
			<label
				data-testid="deleteSessionModal__labelForDelete"
				className="delete-session-modal__confirm-label"
			>
				To confirm, type "
				<span className="delete-session-modal__delete-text">
					DELETE
				</span>
				" below.
				<input
					type="text"
					className="delete-session-modal__input-confirm"
					data-testid="deleteSessionModal__confirm-textarea"
					aria-label="Write delete here to confirm deletion"
					id="confirm"
				/>
			</label>
			<div className="action-item-modal__button-container">
				<button
					type="button"
					className="button__secondary"
					data-testid="deleteSessionModal__cancel-button"
					onClick={() => {
						setModalContents(null);
					}}
				>
					Cancel
				</button>
				<button
					type="submit"
					className="button__primary"
					data-testid="deleteSessionModal__save-button"
				>
					Confirm
				</button>
			</div>
		</form>
	);
}

export default DeleteSessionModal;
