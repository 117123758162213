import VibezDemoAnimation from '../../Assets/LandingPage/vibez-demo-animation.gif';

import './BannerSection.scss';

function BannerSection() {
	return (
		<section className="banner-section">
			<div className="banner-section__text">
				<h1 className="banner-section__title">Vibez:</h1>
				<p className="banner-section__description">
					Go beyond the retro with a quarterly(ish) team health check.
				</p>
			</div>
			<img
				className="banner-section__animation"
				src={VibezDemoAnimation}
				alt="vibes app"
			/>
		</section>
	);
}

export default BannerSection;
