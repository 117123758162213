import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import './Input.scss';

interface Props {
	label: string;
	value?: string;
	onChange?: (value: string) => void;
	onBlur?: (value: string) => void;
	type?: string;
	valid?: boolean;
	placeholder?: string;
	required?: boolean;
	errorText?: string;
	testId?: string;
	inputRef?: any;
	className?: string;
	id?: string;
}

export function Input({
						  label,
						  value = '',
						  type = 'text',
						  valid = true,
						  placeholder,
						  onChange,
						  onBlur,
						  required,
						  errorText,
						  testId,
						  inputRef,
						  className,
						  id
					  }: Props): ReactElement {
	const [isValid, setIsValid] = useState(valid);
	const [inputValue, setInputValue] = useState<string>(value);

	useEffect(() => {
		setIsValid(valid);
	}, [valid]);

	const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setInputValue(value);
		if (onChange) onChange(value);
	};

	return (
		<label className="input__label">
			{label}
			<input
				id={id}
				className={`input__field ${className} ${!isValid ? 'invalid' : ''}`}
				value={inputValue}
				placeholder={placeholder}
				onChange={onInputChange}
				onBlur={(event) => {
					if (onBlur) onBlur(event.target.value);
				}}
				required={required}
				aria-required={required}
				aria-invalid={!isValid}
				data-testid={testId}
				type={type}
				aria-describedby="input__error-message"
				ref={inputRef}
				maxLength={255}
			/>
			{!isValid && errorText && (
				<span
					id="input__error-message"
					className="input__error-message"
					data-testid={`${testId}__error-message`}
				>
					{errorText}
				</span>
			)}
		</label>
	);
}

export default Input;
