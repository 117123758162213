import React from "react";
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { ReactComponent as ActionItemEditIcon } from '../../Assets/Iconify-Icons/bx__bxs-edit.svg';
import { ReactComponent as ActionItemDeleteIcon } from '../../Assets/Iconify-Icons/fa__solid__trash-alt.svg';
import { ActionItem } from '../../RestService/Models/ActionItem';
import RestService from '../../RestService/RestService';
import ActionItemModal from '../../SessionResultsPage/ActionItemModal/ActionItemModal';
import { ActionItemsAtom } from '../../State/ActionItemsAtom';
import { ModalContentsAtom } from '../../State/ModalContentsAtom';
import DateService from '../../Utils/DateService';
import ActionItemConfirmDeleteOverlay from '../ActionItemConfirmDeleteOverlay/ActionItemConfirmDeleteOverlay';

import ActionItemCardDoneButton from './ActionItemCardDoneButton';

import './ActionItemCard.scss';

interface Props {
	actionItem: ActionItem;
	disableModificationButtons?: boolean;
	opaqueContent?: boolean;
}

function ActionItemCard({
	actionItem,
	disableModificationButtons,
	opaqueContent,
}: Props): React.ReactElement {
	const setModal = useSetRecoilState(ModalContentsAtom);
	const [isDeleting, setIsDeleting] = useState(false);
	const [actionItems, setActionItems] = useRecoilState(ActionItemsAtom);

	const onNoDeletingOfActionItem = () => setIsDeleting(false);

	const onYesDeleteActionItem = () => {
		RestService.deleteActionItem(actionItem.id)
			.then(() => {
				setActionItems(
					actionItems.filter(
						(actionItemToFilter) =>
							actionItemToFilter.id !== actionItem.id
					)
				);
			})
			.finally(() => {
				setIsDeleting(false);
			});
	};

	const getActionItemFormattedDate = () =>
		DateService.getFormattedDate(actionItem.dateCreated, 'MMM DD');

	const opacityClass = opaqueContent ? 'opaque' : '';

	return (
		<div
			data-testid={`actionItemCard__item-${actionItem.id}`}
			className="form-container action-item-card"
		>
			{isDeleting && (
				<ActionItemConfirmDeleteOverlay
					onNoClicked={onNoDeletingOfActionItem}
					onYesClicked={onYesDeleteActionItem}
				/>
			)}
			<div
				className={`action-item-card__content ${opacityClass}`}
				data-testid="actionItemCard__content"
			>
				<span className="action-item-card__content-label">Action:</span>
				<p className="action-item-card__content-text">
					{actionItem.action}
				</p>
				<span className="action-item-card__content-label">
					Assigned To:
				</span>
				<p className="action-item-card__content-text">
					{actionItem.assignedTo}
				</p>
			</div>
			<div className="action-item-card__controls">
				<div
					className={`action-item-card__controls-date ${opacityClass} ${
						!opaqueContent && actionItem.isDone
							? 'lightened-border'
							: ''
					}`}
					data-testid={`actionItemCard__controls-date`}
				>
					<span>Created</span>
					<span className="action-item-card__date-text">
						{getActionItemFormattedDate()}
					</span>
				</div>
				<button
					className="action-item-card__controls-button"
					data-testid="actionItemCard__edit-button"
					disabled={disableModificationButtons}
					onClick={() => {
						setModal(<ActionItemModal actionItem={actionItem} />);
					}}
					aria-label={`Edit action item: ${actionItem.action}`}
				>
					<ActionItemEditIcon
						className="action-item-card__controls-icon action-item-card__edit-icon"
						role="presentation"
					/>
					Edit
				</button>
				<button
					className="action-item-card__controls-button"
					data-testid="actionItemCard__delete-button"
					onClick={() => {
						setIsDeleting(!isDeleting);
					}}
					aria-label={`Delete action item: ${actionItem.action}`}
					disabled={disableModificationButtons}
				>
					<ActionItemDeleteIcon
						className="action-item-card__controls-icon action-item-card__delete-icon"
						role="presentation"
					/>
					Delete
				</button>
				<ActionItemCardDoneButton actionItem={actionItem} />
			</div>
		</div>
	);
}

export default ActionItemCard;
