import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { ReactComponent as EditIcon } from '../../Assets/Iconify-Icons/bx__bxs-edit.svg';
import { ReactComponent as PromptDeleteIcon } from '../../Assets/Icons/prompts-delete-x.svg';
import { Prompt } from '../../RestService/Models/Prompt';
import RestService from '../../RestService/RestService';
import { ModalContentsAtom } from '../../State/ModalContentsAtom';
import { PromptsAtom } from '../../State/PromptsAtom';
import PromptModal from '../PromptModal/PromptModal';
import PromptsConfirmDeleteOverlay from '../PromptsConfirmDeleteOverlay/PromptsConfirmDeleteOverlay';

import './PromptCard.scss';

interface Props {
	prompt: Prompt;
}

function PromptCard({ prompt }: Props) {
	const [showDeletionOverlay, setShowDeletionOverlay] = useState(false);
	const [prompts, setPrompts] = useRecoilState(PromptsAtom);
	const setModalContents = useSetRecoilState(ModalContentsAtom);

	const onNoDeletingOfActionItem = () => {
		setShowDeletionOverlay(false);
	};

	const onYesDeleteActionItem = () => {
		RestService.deletePrompt(prompt.id)
			.then((response) => {
				if (response.status === 204) {
					setPrompts(
						prompts.filter(
							(singlePrompt) => singlePrompt.id !== prompt.id
						)
					);
				} else {
					console.error('Delete of prompt failed!');
				}
				setShowDeletionOverlay(false);
			})
			.catch((e) => console.error('Error deleting prompt!', e));
	};

	const showActionButtons = (): boolean => {
		return (
			prompt.category.toUpperCase() === 'CUSTOM' && !showDeletionOverlay
		);
	};

	const openEditModal = () => {
		setModalContents(<PromptModal prompt={prompt} />);
	};

	return (
		<div
			className="prompt-card form-container"
			data-testid={`selectPromptsPage__prompt-card-${prompt.id}`}
		>
			{showDeletionOverlay && (
				<PromptsConfirmDeleteOverlay
					onNoClicked={onNoDeletingOfActionItem}
					onYesClicked={onYesDeleteActionItem}
				/>
			)}
			<div className="select-prompts-page__category">
				{prompt.category}
			</div>
			<h2 className="select-prompts-page__prompt-text">
				{prompt.promptText}
			</h2>
			{showActionButtons() && (
				<>
					<button
						className="prompt-card__delete-button"
						data-testid="promptCard__delete-button"
						aria-label={`Delete ${prompt.category} prompt: ${prompt.promptText}`}
						onClick={() => {
							setShowDeletionOverlay(true);
						}}
					>
						<PromptDeleteIcon
							role="presentation"
							className="prompt-card__delete-button__icon"
						/>
					</button>
					<button
						className="prompt-card__edit-button"
						data-testid="promptCard__edit-button"
						aria-label={`Edit ${prompt.category} prompt: ${prompt.promptText}`}
						onClick={openEditModal}
					>
						<EditIcon
							role="presentation"
							className="prompt-card__edit-button__icon"
						/>
					</button>
				</>
			)}
		</div>
	);
}

export default PromptCard;
