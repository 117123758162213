import React, {useEffect, useRef, useState} from 'react';

import './Accordion.scss';

interface Props {
	index: number;
	isExpanded: boolean;
	title: string;
	children: React.ReactElement;
	onClick: (index: number, title: string) => void;
}

function Accordion({
	index,
	isExpanded,
	title,
	onClick,
	children,
}: Props): React.ReactElement {
	const accordion = useRef<HTMLDivElement>(null);
	const [buttonIsInFocus, setButtonIsInFocus] = useState<boolean>(false);

	useEffect(() => {
		if (accordion.current) {
			accordion.current.style.maxHeight = isExpanded
				? accordion.current.scrollHeight + 'px'
				: '0';
		}
	}, [isExpanded]);

	return (
		<div
			className={`accordion ${
				buttonIsInFocus ? 'accordion__in-focus' : ''
			}`}
			role="presentation"
		>
			<h2 className="accordion__header">
				<button
					data-testid={`accordionHeader__button-${index}`}
					id={`accordionHeader__button-${index}`}
					className={`accordion__button ${
						isExpanded ? '' : 'collapsed'
					}`}
					aria-expanded={isExpanded}
					aria-controls={`collapse-${index}`}
					onClick={() => onClick(index, title)}
					onFocus={() => setButtonIsInFocus(true)}
					onBlur={() => setButtonIsInFocus(false)}
				>
					{title}
				</button>
			</h2>
			<div
				ref={accordion}
				data-testid={`accordionContent__${index}`}
				className="accordion__expanded-section"
				aria-hidden={!isExpanded}
				hidden={!isExpanded}
			>
				<div
					className="accordion__content"
					role="region"
					id={`collapse-${index}`}
					aria-labelledby={`accordionHeader__button--${index}`}
				>
					{children}
				</div>
			</div>
		</div>
	);
}

export default Accordion;
