import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import Banner from '../Banner/Banner';
import {
	DEFAULT_VIBE_VALUE,
	SingleAnonymousAnswerForPrompt,
} from '../RestService/Models/SingleAnonymousAnswerForPrompt';
import RestService from '../RestService/RestService';
import {sessionResultPath} from '../Routes/RouteConstants';
import {PromptsAtom} from '../State/PromptsAtom';
import {getAnswersForPrompt, SubmissionState, UserAnswersAtom} from '../State/UserAnswersAtom';
import useGetSession from '../Utils/hooks/useGetSession';

import SinglePrompt from './SinglePrompt/SinglePrompt';

import './PromptsPage.scss';

function PromptsPage() {
	const navigate = useNavigate();

	const session = useGetSession();
	const [prompts, setPrompts] = useRecoilState(PromptsAtom);
	const [userAnswers, setUserAnswers] = useRecoilState(UserAnswersAtom);

	const [activePromptIndex, setActivePromptIndex] = useState<number>(0);
	const [isSubmittingAnswers, setIsSubmittingAnswers] =
		useState<boolean>(false);

	useEffect(() => {
		if (prompts.length === 0) {
			RestService.getCurrentPrompts()
				.then(setPrompts)
				.catch(console.error);
		}
	}, [prompts, setPrompts]);

	const getActivePrompt = () => prompts[activePromptIndex];

	const handleResultSubmission = () => {
		setIsSubmittingAnswers(true);
		RestService.submitAnswers(userAnswers.answers)
			.then(() => {
				const sessionId = session?.id!;
				setUserAnswers({
					...userAnswers,
					sessionId,
					submissionState: SubmissionState.SUBMITTED,
				});
				navigate(sessionResultPath(sessionId));
			})
			.catch(console.error)
			.finally(() => setIsSubmittingAnswers(false));
	};

	const nextPrompt = () => {
		if (activePromptIndex !== prompts.length - 1) {
			setActivePromptIndex(activePromptIndex + 1);
		}
	};

	const previousPrompt = () => {
		setActivePromptIndex(activePromptIndex - 1);
	};

	const isNotLastPrompt = (): boolean =>
		activePromptIndex !== prompts.length - 1;

	const isNotFirstPrompt = (): boolean => activePromptIndex !== 0;

	const getCurrentAnswer = (): SingleAnonymousAnswerForPrompt | undefined => {
		const activePrompt = getActivePrompt();
		if (activePrompt) {
			const promptId = activePrompt.id;
			return getAnswersForPrompt(userAnswers.answers, promptId);
		} else {
			return undefined;
		}
	};

	const emoticonIsNotSelected = () => {
		const promptResult = getCurrentAnswer();
		return promptResult
			? promptResult.vibeValue === DEFAULT_VIBE_VALUE
			: true;
	};

	return (
		<div className="prompts">
			<Banner
				title="How do you feel?"
				subText="Please rate how each statement below resonates with you."
				date={session?.dateStarted ?? ''}
			/>
			<div
				className="prompts__card-index"
				data-testid="prompts__cardIndex"
			>
				Statement {activePromptIndex + 1} of {prompts.length}
			</div>
			{!!(prompts.length && prompts[activePromptIndex]) && (
				<SinglePrompt prompt={prompts[activePromptIndex]}/>
			)}
			<div className="prompts__navigation-buttons">
				{isNotFirstPrompt() && (
					<button
						className="prompts__navigation-button button__primary"
						data-testid="prompts__back-button"
						onClick={previousPrompt}
					>
						Back
					</button>
				)}
				{isNotLastPrompt() ? (
					<button
						className="prompts__navigation-button button__primary"
						data-testid="prompts__next-button"
						onClick={nextPrompt}
						disabled={emoticonIsNotSelected()}
					>
						Next
					</button>
				) : (
					<button
						className="prompts__navigation-button button__primary"
						disabled={
							emoticonIsNotSelected() || isSubmittingAnswers
						}
						data-testid="prompts__submit-button"
						onClick={handleResultSubmission}
					>
						Submit
					</button>
				)}
			</div>
		</div>
	);
}

export default PromptsPage;
