import { useEffect, useState } from 'react';

import './Version.scss';

const NODE_ENV = process.env.NODE_ENV;

function Version() {
	const [appVersion, setAppVersion] = useState<string>('');

	useEffect(() => {
		fetchAndSetAppVersion();
	}, [setAppVersion]);

	const fetchAndSetAppVersion = () => {
		if (NODE_ENV !== 'test') {
			fetch('/version.txt')
				.then((r) => r.text())
				.then(setAppVersion);
		}
	};

	return <div className="app-version">v. {appVersion}</div>;
}

export default Version;
