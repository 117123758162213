import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';

import Vibez5miley from '../Assets/Icons/vibez-5.png';
import Banner from '../Banner/Banner';
import EndSessionButton from '../EndSessionButton/EndSessionButton';
import {Prompt} from '../RestService/Models/Prompt';
import {SingleAnonymousAnswerForPrompt} from '../RestService/Models/SingleAnonymousAnswerForPrompt';
import RestService from '../RestService/RestService';
import {ModalContentsAtom} from '../State/ModalContentsAtom';
import {PromptsAtom} from '../State/PromptsAtom';
import {TeamAnswerAtom} from '../State/TeamAnswersAtom';
import {AllSubmittedAnswers} from '../Types/AllSubmittedAnswers';
import useGetSession from '../Utils/hooks/useGetSession';

import CollapsedSinglePromptResult from './CollapsedSinglePromptResult/CollapsedSinglePromptResult';
import DeleteSessionModal from './DeleteSessionModal/DeleteSessionModal';
import ExpandedSinglePromptResult from './ExpandedSinglePromptResult/ExpandedSinglePromptResult';
import VotesToggle from './VotesToggle/VotesToggle';

import './SessionResultsPage.scss';

function SessionResultsPage() {
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const [prompts, setPrompts] = useRecoilState(PromptsAtom);
	const [allSubmittedAnswers, setAllSubmittedAnswers] =
		useRecoilState(TeamAnswerAtom);
	const session = useGetSession();
	const [historicalPrompts, setHistoricalPrompts] = useState<Array<Prompt>>(
		[]
	);
	const {sessionId = ''} = useParams<{ sessionId: string }>();

	useEffect(() => {
		if (prompts.length === 0) {
			RestService.getCurrentPrompts()
				.then((promptsSortedByCategory) => {
					setPrompts(promptsSortedByCategory);
				})
				.catch(console.error);
		}
	}, [prompts, setPrompts]);

	const fetchResults = useCallback(() => {
		if (sessionId) {
			RestService.getAnswersBySessionId(sessionId)
				.then((answers) => {
					setAllSubmittedAnswers(groupAnswersByPromptId(answers));
				})
				.catch(console.error);
		}
	}, [setAllSubmittedAnswers, sessionId]);

	useEffect(() => {
		const teamPromptsIds = prompts.map((p) => p.id);
		const historicalPromptIds = Object.keys(allSubmittedAnswers).filter(
			(id) => {
				return !teamPromptsIds.includes(id);
			}
		);
		if (Object.keys(allSubmittedAnswers).length > 0) {
			RestService.getPromptsByIds(historicalPromptIds).then(
				(histPrompts) => {
					setHistoricalPrompts(histPrompts);
				}
			);
		}
	}, [allSubmittedAnswers, prompts]);

	useEffect(() => {
		fetchResults();
	}, [fetchResults]);

	function groupAnswersByPromptId(
		responseData: Array<SingleAnonymousAnswerForPrompt>
	): AllSubmittedAnswers {
		const newSubmittedAnswers: AllSubmittedAnswers = {};

		responseData.forEach((answer) => {
			if (!newSubmittedAnswers[answer.promptId]) {
				newSubmittedAnswers[answer.promptId] = [];
			}
			newSubmittedAnswers[answer.promptId].push(answer);
		});

		return newSubmittedAnswers;
	}

	function onPromptResultCardClick(
		prompt: Prompt,
		sortedAnswersForPrompt: Array<SingleAnonymousAnswerForPrompt>
	) {
		setModalContents(
			<ExpandedSinglePromptResult
				prompt={prompt}
				answersForPrompt={sortedAnswersForPrompt}
				onClick={() => setModalContents(null)}
				dataTestId='expandedSinglePromptResult'
			/>
		);
	}

	const NoSessionResultsCard = () => (
		<div
			data-testid="sessionResults__no-answers"
			className="session-results__no-answers form-container"
			role="alert"
		>
			<img
				src={Vibez5miley}
				alt="No answers submitted for current session."
				className="session-results__no-answers-logo"
			/>
			<h1>No results yet!</h1>
		</div>
	);

	const answersExist = !!Object.keys(allSubmittedAnswers).length;

	function deleteHealthCheck() {
		setModalContents(<DeleteSessionModal sessionId={sessionId}/>);
	}

	return (
		<div className="session-results">
			<div className="session-results__banner-container">
				<Banner
					title="Your team's results:"
					date={session?.dateStarted ?? ''}
				>
					{!session?.dateCompleted && <VotesToggle/>}
				</Banner>
			</div>
			{answersExist ? (
				<ul className="session-results__cards">
					{[...prompts, ...historicalPrompts].map((prompt, index) => {
						const potentialAnswersForPrompt =
							allSubmittedAnswers[prompt.id] || [];
						const answersForPrompt = [...potentialAnswersForPrompt];

						if (!answersForPrompt.length)
							return (
								<React.Fragment
									key={`noPromptResult${prompt.id}-${index}`}
								/>
							);

						const sortedAnswersForPrompt: Array<SingleAnonymousAnswerForPrompt> =
							answersForPrompt.sort(
								(a, b) => a.vibeValue - b.vibeValue
							);

						return (
							<li key={`promptResult${prompt.id}-${index}`}>
								<CollapsedSinglePromptResult
									prompt={prompt}
									answersForPrompt={sortedAnswersForPrompt}
									onClick={() =>
										onPromptResultCardClick(
											prompt,
											sortedAnswersForPrompt
										)
									}
								/>
							</li>
						);
					})}
				</ul>
			) : (
				<NoSessionResultsCard/>
			)}
			<EndSessionButton/>
			{session?.dateCompleted && (
				<div className="session-results__delete-button-container">
					<button
						className="session-results__delete-button"
						onClick={deleteHealthCheck}
					>
						DELETE THIS HEALTH CHECK
					</button>
				</div>
			)}
		</div>
	);
}

export default SessionResultsPage;
