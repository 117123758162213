import { atom, selector } from 'recoil';

import { AllSubmittedAnswers } from '../Types/AllSubmittedAnswers';

export const TeamAnswerAtom = atom<AllSubmittedAnswers>({
	key: 'teamAnswerAtom',
	default: {},
});

export const TeamAnswerSelector = selector<AllSubmittedAnswers>({
	key: 'TeamAnswerSelector',
	get: ({ get }) => {
		return get(TeamAnswerAtom);
	},
	set: ({ set, get }, newAnswers) => {
		const newSubmittedAnswers = newAnswers as AllSubmittedAnswers;

		const allSubmittedAnswers: AllSubmittedAnswers = JSON.parse(
			JSON.stringify(get(TeamAnswerAtom))
		);

		const newAnswerPromptIds = Object.keys(newSubmittedAnswers);

		newAnswerPromptIds.forEach((promptId) => {
			const newAnswerForPrompt = newSubmittedAnswers[promptId][0];
			if (!allSubmittedAnswers[promptId])
				allSubmittedAnswers[promptId] = [];
			allSubmittedAnswers[promptId].push(newAnswerForPrompt);
		});

		set(TeamAnswerAtom, allSubmittedAnswers);
	},
});
