import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import EmoticonRadioButton from '../Emoticon/EmoticonRadioButton';
import {DEFAULT_COMMENT_VALUE, DEFAULT_VIBE_VALUE,} from '../RestService/Models/SingleAnonymousAnswerForPrompt';
import RestService from '../RestService/RestService';
import {HEALTH_CHECK_ENDED_PATH} from '../Routes/RouteConstants';
import {FeedbackOriginAtom} from '../State/FeedbackOriginAtom';

import './FeedbackPage.scss';

function FeedbackPage(): React.ReactElement {
	const [feedbackValue, setFeedbackValue] =
		useState<number>(DEFAULT_VIBE_VALUE);
	const [submitActive, setSubmitActive] = useState<boolean>(false);
	const [comment, setComment] = useState<string | null>(
		DEFAULT_COMMENT_VALUE
	);
	const feedbackIsFromEndOfSession = useRecoilValue(FeedbackOriginAtom);
	const navigate = useNavigate();

	function handleOnCommentChange(
		event: React.ChangeEvent<HTMLTextAreaElement>
	) {
		const updatedComment = event.target.value;
		setComment(updatedComment);
	}

	const selectedEmoticonChanged = (updatedEmoticonValue: number) => {
		setFeedbackValue(updatedEmoticonValue);
		setSubmitActive(true);
	};

	const writeFeedbackText = () => {
		return feedbackIsFromEndOfSession ? (
			<p className="feedback-page__paragraph">
				You’ve completed a health check! Please take a moment to let us
				know how you feel about this product. If you’re willing to chat
				with us about your experience, please include your CDSID. Thank
				you!
			</p>
		) : (
			<p className="feedback-page__paragraph">
				Your answers will help us improve existing features and add new
				ones. If you're willing to chat about your experience, please
				include your CDSID. Thank you!
			</p>
		);
	};

	function submitFeedback() {
		RestService.sendFeedback(feedbackValue, comment);
		navigate(HEALTH_CHECK_ENDED_PATH);
	}

	return (
		<div className="feedback-page" data-testid="feedbackPage">
			<h1 className="feedback-page__header">Feedback</h1>
			{writeFeedbackText()}
			<div
				className="feedback-page form-container"
				data-testid="feedback-page__card"
			>
				<p className="feedback-page__card-paragraph">
					Tell us about your VIBEZ experience! (What did you like?
					What would you change? What was missing?)
				</p>
				<div className="feedback-page__card-emoticons">
					{[1, 2, 3, 4, 5].map((emoticonIndex) => {
						const shouldGrayOut =
							feedbackValue !== DEFAULT_VIBE_VALUE &&
							feedbackValue !== emoticonIndex;

						return (
							<EmoticonRadioButton
								key={`Emoticon${emoticonIndex}`}
								onEmoticonFocus={selectedEmoticonChanged}
								value={emoticonIndex}
								grayedOut={shouldGrayOut}
								selectedEmoticon={feedbackValue}
							/>
						);
					})}
				</div>
				<textarea
					placeholder="(Optional) Write your comments here"
					className="feedback-page__card-comments"
					data-testid="feedback-page__textInputWindow"
					aria-label="Optional, Write your comments here"
					value={comment ?? ''}
					onChange={handleOnCommentChange}
					maxLength={10000}
				/>
			</div>
			<div className="feedback-page__submit-button-container">
				<button
					className="feedback-page__card-submit-button button__primary"
					disabled={!submitActive}
					data-testid="feedback-page__submitButton"
					onClick={submitFeedback}
				>
					Submit
				</button>
			</div>
		</div>
	);
}

export default FeedbackPage;
