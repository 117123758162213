import {isExpired} from 'react-jwt';
import axios, {AxiosResponse} from 'axios';

import CookieService from '../CookieService/CookieService';
import {CREATE_TEAM_PATH, LOGIN_PATH, SERVER_ERROR_PAGE_PATH,} from '../Routes/RouteConstants';
import {sortActionItems} from '../State/ActionItemsAtom';

import {ActionItem} from './Models/ActionItem';
import {CreateActionItemRequest} from './Models/CreateActionItemRequest';
import {CreateTeamRequest} from './Models/CreateTeamRequest';
import {EditActionItemRequest} from './Models/EditActionItemRequest';
import {LoginBody} from './Models/LoginBody';
import {LoginResponse} from './Models/LoginResponse';
import {PasswordResetRequest} from './Models/PasswordResetRequest';
import {Prompt} from './Models/Prompt';
import {PromptRequest} from './Models/PromptRequest';
import {Session} from './Models/Session';
import {SingleAnonymousAnswerForPrompt} from './Models/SingleAnonymousAnswerForPrompt';
import {
	ACTION_ITEMS_API_PATH,
	ACTION_ITEMS_BY_SESSION_API_PATH,
	ACTION_ITEMS_OF_ALL_SESSIONS_API_PATH,
	ANSWERS_API_PATH,
	ANSWERS_BY_SESSION_API_PATH,
	CHAT_API_URL,
	CURRENT_PROMPTS_API_PATH,
	CURRENT_SESSION_API_PATH,
	FEEDBACK_API_URL,
	LOGIN_API_PATH,
	PROMPTS_API_PATH,
	REQUEST_PASSWORD_RESET_PATH,
	REQUEST_RECOVER_TEAM_NAME_PATH,
	SESSIONS_API_PATH,
	TEAM_CHANGE_PASSWORD_PATH,
	TEAM_DETAILS_API_PATH,
	TEAMS_API_PATH,
	USER_RESET_PASSWORD_API_PATH,
} from './ApiConstants';

const NODE_ENV = process.env.NODE_ENV;

const getAuthConfig = () => {
	let config = {};
	const accessToken = CookieService.getToken();
	if (accessToken) {
		config = {headers: {Authorization: 'Bearer ' + accessToken}};
	}
	return config;
};

const UNAUTHORIZED_STATUS = 401;
const SERVER_ERROR_STATUS = 500;
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		const {status} = error?.response;

		const accessToken = CookieService.getToken();
		const isAccessTokenExpired = isExpired(accessToken);

		if (status === UNAUTHORIZED_STATUS) {
			CookieService.clearToken();

			const {pathname} = window.location;
			const isLoginPage = pathname.includes(LOGIN_PATH);
			const isCreateNewTeamPage = pathname === CREATE_TEAM_PATH;

			if (isAccessTokenExpired && !isLoginPage && !isCreateNewTeamPage) {
				window.location.pathname = LOGIN_PATH;
			}
		}

		if (status === SERVER_ERROR_STATUS) {
			window.location.pathname = SERVER_ERROR_PAGE_PATH;
		}

		return Promise.reject(error);
	}
);

if (NODE_ENV !== 'test') {
	axios.defaults.baseURL = '/api';
}

function sortPrompts(response: AxiosResponse<any>) {
	const promptsSortedByCategory: Array<Prompt> = [...response.data]
		.map((prompt): Prompt => ({...prompt, isDone: false}))
		.sort((prompt1, prompt2) => {
			if (prompt1.category === 'Custom') {
				return 1;
			} else if (prompt2.category === 'Custom') {
				return -1;
			}
			return prompt1.category.localeCompare(prompt2.category);
		});
	return promptsSortedByCategory;
}

const RestService = {
	sendFeedback(value: number, comment: string | null) {
		const config = getAuthConfig();
		const messageBody = {
			value: value,
			comment: comment,
		};
		return axios.post(FEEDBACK_API_URL, messageBody, config);
	},
	sendChat(chatMessage: string) {
		const config = getAuthConfig();
		return axios.post(CHAT_API_URL, {chatMessage: chatMessage}, config)
	},
	deleteSession(sessionId: string) {
		const config = getAuthConfig();
		return axios.delete(SESSIONS_API_PATH + '/' + sessionId, config);
	},
	createPrompt: (addPromptRequest: PromptRequest): Promise<Prompt> => {
		const config = getAuthConfig();
		return axios
			.post(PROMPTS_API_PATH, addPromptRequest, config)
			.then((response) => response.data);
	},

	editPrompt: (
		promptId: string,
		editPromptRequest: PromptRequest
	): Promise<Prompt> => {
		const config = getAuthConfig();
		return axios
			.put(`${PROMPTS_API_PATH}/${promptId}`, editPromptRequest, config)
			.then((response) => response.data);
	},

	createNewTeam: (
		createTeamRequest: CreateTeamRequest
	): Promise<AxiosResponse<void>> => {
		return axios.post(TEAMS_API_PATH, createTeamRequest);
	},

	requestPasswordReset: (passwordResetRequest: PasswordResetRequest) => {
		return axios.post(REQUEST_PASSWORD_RESET_PATH, passwordResetRequest);
	},

	changePassword: (token: string, password: string) => {
		return axios.post(TEAM_CHANGE_PASSWORD_PATH, {
			resetKey: token,
			newPassword: password,
		});
	},

	requestRecoverTeamName(email: string) {
		return axios.post(REQUEST_RECOVER_TEAM_NAME_PATH, {email});
	},

	login: (loginBody: LoginBody): Promise<AxiosResponse<LoginResponse>> => {
		return axios.post(LOGIN_API_PATH, loginBody);
	},

	createSession: (accessToken?: string): Promise<Session> => {
		const config = accessToken
			? {headers: {Authorization: 'Bearer ' + accessToken}}
			: getAuthConfig();
		return axios.post(SESSIONS_API_PATH, null, config).then((response) => {
			return response.data
		});
	},

	endSession: (askForFeedback: boolean): Promise<void> => {
		const config = getAuthConfig();
		const data = {completed: true};
		let endSessionPath = `${SESSIONS_API_PATH}?feedback=${askForFeedback}`;
		return axios.patch(endSessionPath, data, config);
	},

	getCurrentPrompts: (): Promise<Array<Prompt>> => {
		const config = getAuthConfig();
		return axios.get(CURRENT_PROMPTS_API_PATH, config).then((response) => {
			return sortPrompts(response);
		});
	},

	getPromptsByIds: (promptIds: Array<string>): Promise<Array<Prompt>> => {
		const config = getAuthConfig();
		return axios
			.get(`${PROMPTS_API_PATH}?ids=${promptIds.join(',')}`, config)
			.then((response) => {
				return sortPrompts(response);
			});
	},

	deletePrompt: (promptId: string): Promise<AxiosResponse<void>> => {
		const config = getAuthConfig();
		return axios.delete(`${PROMPTS_API_PATH}/${promptId}`, config);
	},

	submitAnswers: (
		answers: Array<SingleAnonymousAnswerForPrompt>
	): Promise<AxiosResponse<void>> => {
		const config = getAuthConfig();
		return axios.post(ANSWERS_API_PATH, answers, config);
	},

	getAnswersBySessionId: (
		sessionId: string
	): Promise<Array<SingleAnonymousAnswerForPrompt>> => {
		const config = getAuthConfig();
		return axios
			.get(`${ANSWERS_BY_SESSION_API_PATH}/${sessionId}`, config)
			.then((response) => response.data);
	},

	addActionItem: (
		createActionItemRequest: CreateActionItemRequest
	): Promise<ActionItem> => {
		const config = getAuthConfig();
		return axios
			.post(ACTION_ITEMS_API_PATH, createActionItemRequest, config)
			.then((response) => response.data);
	},

	editActionItem: (
		actionItemId: string,
		editActionItemRequest: EditActionItemRequest
	): Promise<ActionItem> => {
		const config = getAuthConfig();
		return axios
			.put(
				`${ACTION_ITEMS_API_PATH}/${actionItemId}`,
				editActionItemRequest,
				config
			)
			.then((response) => response.data);
	},

	deleteActionItem: (
		actionItemToDelete: string
	): Promise<AxiosResponse<void>> => {
		const config = getAuthConfig();
		return axios.delete(
			`${ACTION_ITEMS_API_PATH}/${actionItemToDelete}`,
			config
		);
	},

	getActionItemsForSession(sessionId: string) {
		const config = getAuthConfig();
		return axios
			.get(`${ACTION_ITEMS_BY_SESSION_API_PATH}/${sessionId}`, config)
			.then((response) => sortActionItems(response.data));
	},

	getAllActionItemsForAllSessions: (): Promise<Array<ActionItem>> => {
		const config = getAuthConfig();
		return axios
			.get(ACTION_ITEMS_OF_ALL_SESSIONS_API_PATH, config)
			.then((response) => sortActionItems(response.data));
	},

	getCurrentSession: (): Promise<Session> => {
		const config = getAuthConfig();
		return axios
			.get(CURRENT_SESSION_API_PATH, config)
			.then((response) => response.data);
	},

	getSessionById: (sessionId: string): Promise<Session> => {
		const config = getAuthConfig();
		return axios
			.get(`${SESSIONS_API_PATH}/${sessionId}`, config)
			.then((response) => response.data);
	},

	getAllSessionsForTeam: (): Promise<Array<Session>> => {
		const config = getAuthConfig();
		return axios
			.get(SESSIONS_API_PATH, config)
			.then((response) => response.data);
	},
	changeTeamDetails(teamDetailsEmail: string, teamDetailsTeamName: string) {
		const config = getAuthConfig();
		return axios.put(
			TEAM_DETAILS_API_PATH,
			{teamName: teamDetailsTeamName, email: teamDetailsEmail},
			config
		);
	},
	userChangePassword(oldPassword: string, newPassword: string) {
		const config = getAuthConfig();
		return axios
			.post(
				USER_RESET_PASSWORD_API_PATH,
				{oldPassword: oldPassword, newPassword: newPassword},
				config
			)
			.catch(function (error) {
				if (error.response.status === 400) {
					return 400;
				} else return error.response.status;
			});
	},
};

export default RestService;
