import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {ReactComponent as Trash} from '../Assets/Iconify-Icons/fa__solid__trash-alt.svg';
import {Session} from '../RestService/Models/Session';
import RestService from '../RestService/RestService';
import {sessionResultPath} from '../Routes/RouteConstants';
import DeleteSessionModal from '../SessionResultsPage/DeleteSessionModal/DeleteSessionModal';
import {ModalContentsAtom} from '../State/ModalContentsAtom';
import {SessionsAtom} from '../State/SessionsAtom';
import DateService from '../Utils/DateService';

import NoSessionsFound from './NoSessionsFound';

import './HistoricalResultsPage.scss';

function HistoricalResultsPage(): React.ReactElement {
	const [sessions, setSessions] = useRecoilState(SessionsAtom);
	const setModalContents = useSetRecoilState(ModalContentsAtom);

	useEffect(() => {
		RestService.getAllSessionsForTeam()
			.then((allSessions) => {
				const sortedSessions = [...allSessions].sort(
					sortSessionsByDate
				);
				setSessions(sortedSessions);
			})
			.catch(console.error);
	}, [setSessions]);

	const sortSessionsByDate = (a: Session, b: Session): number => {
		const date1 = new Date(a.dateStarted).getTime();
		const date2 = new Date(b.dateStarted).getTime();
		if (date1 < date2 || !b.dateCompleted) return 1;
		if (date1 > date2 || !a.dateCompleted) return -1;
		return 0;
	};

	return (
		<div className="history-page">
			<h1 className="history-page__title">Results</h1>
			{sessions.length > 0 ? (
				<ul className="history-page__card-box">
					{sessions.map((session, index) => {
						const date = DateService.getFormattedDate(
							session.dateStarted,
							'MMMM DD, YYYY'
						);
						const time = DateService.getFormattedDate(
							session.dateStarted,
							'h:mm A'
						);
						const isSessionInProgress = !session.dateCompleted;
						let ariaLabel = `Vibes Session started on ${date} at ${time}.`;
						ariaLabel += isSessionInProgress
							? ' This session is in progress.'
							: ' This session has been completed.';
						return (
							<li key={`session-${index}`}>
								<div className="history-page__single-card-container">
									<Link
										to={sessionResultPath(session.id)}
										data-testid={`historyPage__history-card-${index}`}
										className="history-page__card form-container"
										aria-label={ariaLabel}
									>
										<div className="history-page__card-info">
											<div className="history-page__card-date">
												{date}
											</div>
											<div className="history-page__card-time">
												{time}
											</div>
										</div>
										{isSessionInProgress && (
											<div className="history-page__in-progress button__primary">
												In Progress
											</div>
										)}
									</Link>
									{!isSessionInProgress && (
										<div
											className="history-page__delete-session-container"
											data-testid={`history-page__deleteButton-${index}`}
										>
											<button
												className="history-page__delete-session-button"
												aria-label={`Delete session from ${date} at ${time}`}
												onClick={() =>
													setModalContents(
														<DeleteSessionModal
															sessionId={
																session.id
															}
														/>
													)
												}
											>
												<Trash className="history-page__trash-icon"/>
											</button>
										</div>
									)}
								</div>
							</li>
						);
					})}
				</ul>
			) : (
				<NoSessionsFound/>
			)}
		</div>
	);
}

export default HistoricalResultsPage;
