import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import AddActionItemButton from '../ActionItemComponents/AddActionItemButton';
import ActionItemCard from '../ActionItemsPage/ActionItemCard/ActionItemCard';
import { ReactComponent as CheckedCheckbox } from '../Assets/Iconify-Icons/bx__checkbox-checked.svg';
import Banner from '../Banner/Banner';
import EndSessionButton from '../EndSessionButton/EndSessionButton';
import { ActionItem } from '../RestService/Models/ActionItem';
import ActionItemModal from '../SessionResultsPage/ActionItemModal/ActionItemModal';
import { ActionItemsAtom } from '../State/ActionItemsAtom';
import { ModalContentsAtom } from '../State/ModalContentsAtom';
import useGetSession from '../Utils/hooks/useGetSession';

import './SessionActionItemsPage.scss';

export const getActionItemsForSession = (
	actionItems: Array<ActionItem>,
	sessionId: string
) => {
	return actionItems.filter((item) => item.sessionId === sessionId) || [];
};

function SessionActionItemsPage() {
	const { sessionId = '' } = useParams<{ sessionId: string }>();
	const actionItems = useRecoilValue(ActionItemsAtom);
	const actionItemsForSession = getActionItemsForSession(
		actionItems,
		sessionId
	);
	const session = useGetSession();
	const setModalContents = useSetRecoilState(ModalContentsAtom);

	const NoActionsCard = () => (
		<div
			role="alert"
			className="session-action-items__no-items-card form-container"
		>
			<CheckedCheckbox
				role="presentation"
				className="session-action-items__no-items-card-icon"
			/>
			<h2 className="session-action-items__no-items-text">
				No action items yet!
			</h2>
		</div>
	);

	function actionItemButtonClickHandler() {
		setModalContents(<ActionItemModal />);
	}

	return (
		<div className="session-action-items">
			<div className="session-action-items__banner-container">
				<Banner title="Action Items:" date={session?.dateStarted ?? ''}>
					<AddActionItemButton
						className="button__primary"
						onClickHandler={actionItemButtonClickHandler}
					/>
				</Banner>
			</div>
			{actionItemsForSession.length ? (
				<ul className="session-action-items__cards">
					{actionItemsForSession.map((actionItem) => (
						<li key={`action-item-${actionItem.id}`}>
							<ActionItemCard
								actionItem={actionItem}
								disableModificationButtons={actionItem.isDone}
								opaqueContent={actionItem.isDone}
							/>
						</li>
					))}
				</ul>
			) : (
				<NoActionsCard />
			)}
			<EndSessionButton />
		</div>
	);
}

export default SessionActionItemsPage;
