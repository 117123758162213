import { FormEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '../Input/Input';
import RestService from '../RestService/RestService';
import { CREATE_TEAM_SUCCESS_PATH } from '../Routes/RouteConstants';

export interface Team {
	name: string;
	email: string;
}

export interface TeamWithPassword extends Team {
	password: string;
}

interface Passwords {
	password: string;
	passwordConfirm: string;
}

function CreateNewTeamFormPage() {
	const [nameIsInUse, setNameIsInUse] = useState<boolean>(false);
	const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
	const nameInputRef = useRef<HTMLInputElement>(null);
	const emailInputRef = useRef<HTMLInputElement>(null);
	const [nameIsValid, setNameIsValid] = useState<boolean>(true);
	const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
	const [passwordData, setPasswordData] = useState<Passwords>({
		password: '',
		passwordConfirm: '',
	});
	const navigate = useNavigate();

	const validatePasswords = () => {
		const { password, passwordConfirm } = passwordData;
		if (password && passwordConfirm) {
			let passwordValidation = password === passwordConfirm;
			setPasswordsMatch(passwordValidation);
			return passwordValidation;
		} else {
			setPasswordsMatch(false);
			return false;
		}
	};

	const validateEmail = () => {
		setEmailIsValid(emailInputRef.current?.value.includes('@ford.com') ?? false);
		return emailInputRef.current?.value.includes('@ford.com')
	};

	const validateName = () => {
		setNameIsValid(nameInputRef.current?.validity.valid ?? false);
	};

	const onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		let passwordIsValid = validatePasswords();
		if (passwordIsValid && validateEmail()) {
			RestService.createNewTeam({
				name: nameInputRef.current!.value,
				email: emailInputRef.current!.value,
				password: passwordData.password,
			})
				.then(() => {
					navigate(CREATE_TEAM_SUCCESS_PATH);
				})
				.catch((error) => {
					if (error?.response?.status === 409) {
						setNameIsInUse(true);
					} else {
						console.error(error);
					}
				});
		}
	};

	return (
		<form
			className="form-container"
			onSubmit={onSubmit}
			onInvalid={(e) => {
				e.preventDefault();
				validatePasswords();
				validateEmail();
				validateName();
			}}
			data-testid="createNewTeamForm"
		>
			<h1>Create a New Team</h1>
			<Input
				label="Team Name"
				errorText={
					nameIsInUse
						? 'This team name already in use.'
						: 'Team Name is required.'
				}
				valid={!nameIsInUse && nameIsValid}
				testId="createNewTeamForm__team-name"
				onChange={() => {
					setNameIsInUse(false);
					setNameIsValid(true);
				}}
				required
				inputRef={nameInputRef}
			/>
			<Input
				label="Password"
				type="password"
				errorText="Password is required and must match confirmation."
				valid={passwordsMatch}
				onChange={(password) => {
					setPasswordData({ ...passwordData, password });
				}}
				testId="createNewTeamForm__password"
			/>
			<Input
				label="Confirm Password"
				type="password"
				errorText="Password is required and must match."
				valid={passwordsMatch}
				onChange={(passwordConfirm) => {
					setPasswordData({
						...passwordData,
						passwordConfirm,
					});
				}}
				testId="createNewTeamForm__password-confirm"
			/>
			<Input
				label="Email (for password recovery)"
				type="email"
				errorText="Please specify a Ford email account."
				testId="createNewTeamForm__email"
				onChange={() => {
					setEmailIsValid(true);
				}}
				valid={emailIsValid}
				inputRef={emailInputRef}
				required
			/>
			<button
				data-testid="createNewTeamForm__submit-button"
				type="submit"
				className="button__primary"
			>
				Submit
			</button>
		</form>
	);
}

export default CreateNewTeamFormPage;
