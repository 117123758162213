import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import BaseSecondaryNav from '../BaseSecondaryNav/BaseSecondaryNav';
import RestService from '../RestService/RestService';
import { actionItemsPath, sessionResultPath } from '../Routes/RouteConstants';
import { getActionItemsForSession } from '../SessionActionItemsPage/SessionActionItemsPage';
import { ActionItemsAtom } from '../State/ActionItemsAtom';

function SessionSecondaryNav() {
	const { sessionId = ''} = useParams<{ sessionId: string }>();
	const [actionItems, setActionItems] = useRecoilState(ActionItemsAtom);
	const [actionItemsForSessionCount, setActionItemsForSessionCount] =
		useState<number>(0);

	useEffect(() => {
			RestService.getActionItemsForSession(sessionId)
				.then(setActionItems)
				.catch(console.error);
	}, [setActionItems, sessionId]);

	useEffect(() => {
		const actionItemsForSession = getActionItemsForSession(
			actionItems,
			sessionId
		);
		setActionItemsForSessionCount(actionItemsForSession.length);
	}, [actionItems, sessionId]);

	return (
		<BaseSecondaryNav
			navItems={[
				{
					path: sessionResultPath(sessionId),
					testId: 'sessionSecondaryNav__view-results',
					label: 'View Results',
				},
				{
					path: actionItemsPath(sessionId),
					testId: 'sessionSecondaryNav__view-session-action-items',
					label: `View Action Items (${actionItemsForSessionCount})`,
				},
			]}
		/>
	);
}

export default SessionSecondaryNav;
