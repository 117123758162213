import React from "react";
import { Navigate, Outlet } from 'react-router-dom';

import useAuthenticationContext from '../Utils/hooks/useAuthenticationContext';

import { LOGIN_PATH } from './RouteConstants';

function AuthenticatedRoute(): React.ReactElement {
	const { isAuthenticated } = useAuthenticationContext();

	return isAuthenticated ? <Outlet /> : <Navigate to={LOGIN_PATH} replace />;
}

export default AuthenticatedRoute;
