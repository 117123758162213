import {ReactNode} from 'react';
import {useSetRecoilState} from 'recoil';

import {ReactComponent as AddActionItemImage} from '../../Assets/Iconify-Icons/ant-design__plus-outlined.svg';
import {ReactComponent as CommentBubbleImage} from '../../Assets/Iconify-Icons/fa__solid__comment-dots.svg';
import {Prompt} from '../../RestService/Models/Prompt';
import {ModalContentsAtom} from '../../State/ModalContentsAtom';
import ActionItemModal from '../ActionItemModal/ActionItemModal';

import SessionResultCardDoneButton from './SessionResultCardDoneButton';

import './SessionResultCard.scss';

interface Props {
	prompt: Prompt;
	children: ReactNode;
	dataTestId?: string;
	commentsButtonText: string;
	onClick: () => void;
	ariaTitleId?: string;
	isExpanded: boolean;
	className?: string;
}

function SessionResultCard({
							   prompt,
							   children,
							   dataTestId,
							   commentsButtonText,
							   onClick,
							   ariaTitleId,
							   isExpanded,
							   className = ''
						   }: Props) {
	const {category, promptText} = prompt;
	const setModalContents = useSetRecoilState(ModalContentsAtom);


	const isDoneClass = prompt.isDone? 'is-done' : '';
	const isExpandedClass = isExpanded ? 'is-expanded' : '';
	return (
		<div
			className={`${className} session-result-card ${isExpandedClass} form-container ${isDoneClass}`}
			data-testid={dataTestId}
		>
			{/*<div*/}
			{/*	className={`session-result-card__content ${*/}
			{/*		prompt.isDone ? 'is-done' : ''*/}
			{/*	}`}*/}
			{/*	data-testid="sessionResultCard__content"*/}
			{/*>*/}
				<h2 className="session-result-card__category">{category}</h2>
				<p
					className="session-result-card__prompt-text"
					id={ariaTitleId}
				>
					{promptText}
				</p>
				<h2 className="session-result-card__votes-title">Votes:</h2>
				{children}
			{/*</div>*/}
			<div className="session-result-card__actions">
				<button
					className={`session-result-card__comments-button ${
						isDoneClass
					}`}
					data-testid="sessionResultCard__comments-button"
					aria-label={`${category} prompt results card. Click to ${
						!isExpanded ? 'open modal and' : ''
					} ${commentsButtonText}.`}
					onClick={onClick}
					disabled={prompt.isDone}
				>
					<CommentBubbleImage
						role="presentation"
						className="session-result-card__comments-icon"
					/>
					{commentsButtonText}
				</button>
				<button
					className={`session-result-card__action-item-button ${
						isDoneClass
					}`}
					data-testid={`sessionResultCard__action-item-button-${prompt.id}`}
					disabled={prompt.isDone}
					onClick={() => {
						setModalContents(<ActionItemModal/>);
					}}
				>
					<AddActionItemImage
						role="presentation"
						className="session-result-card__add-action-item-icon"
					/>
					Action Item
				</button>
				<SessionResultCardDoneButton prompt={prompt}/>
			</div>
		</div>
	)
		;
}

export default SessionResultCard;
