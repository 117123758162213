import {FormEvent, useEffect, useRef, useState} from 'react';

import Input from '../Input/Input';
import RestService from '../RestService/RestService';

interface Props {
	displayThis: boolean;
	onSubmitSuccess: Function;
	onChange: Function;
}

function PasswordUpdateTab({displayThis, onSubmitSuccess, onChange}: Props) {
	const oldPasswordRef = useRef<HTMLInputElement | null>(null);
	const newPasswordRef = useRef<HTMLInputElement | null>(null);
	const verifyPasswordRef = useRef<HTMLInputElement | null>(null);

	const [oldPassword, setOldPassword] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');
	const [verifyPassword, setVerifyPassword] = useState<string>('');

	const [oldPasswordValid, setOldPasswordValid] = useState<boolean>(true);
	const [newPasswordValid, setNewPasswordValid] = useState<boolean>(true);
	const [verifyPasswordValid, setVerifyPasswordValid] =
		useState<boolean>(true);

	const [responseInvalid, setResponseInvalid] = useState<boolean>(false);

	useEffect(() => {
		onChange();
	}, [newPassword, oldPassword, verifyPassword, onChange]);

	function isOldPasswordCorrect() {
		if (oldPasswordValid) {
			return !responseInvalid;
		} else return false;
	}

	function validateOldPassword() {
		setOldPasswordValid(oldPasswordRef.current?.validity.valid ?? false);
		return oldPasswordRef.current?.validity.valid;
	}

	function validateNewPassword() {
		setNewPasswordValid(newPasswordRef.current?.validity.valid ?? false);
		return newPasswordRef.current?.validity.valid;
	}

	function validateVerifyPassword(): boolean {
		let isValid: boolean = !!verifyPassword && verifyPassword === newPassword;
		setVerifyPasswordValid(isValid);
		return isValid;
	}

	function validatePasswordChange() {
		let oldPasswordValid = validateOldPassword();
		let newPasswordValid = validateNewPassword();
		let verifyPasswordValid = validateVerifyPassword();
		return oldPasswordValid && newPasswordValid && verifyPasswordValid;
	}

	function onPasswordResetSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		if (validatePasswordChange()) {
			setResponseInvalid(false);
			RestService.userChangePassword(oldPassword, newPassword).then(
				(result) => {
					if (result === 400) {
						setResponseInvalid(true);
						setOldPasswordValid(false);
					} else if (result.status === 204) {
						onSubmitSuccess();
						oldPasswordRef.current = null;
						newPasswordRef.current = null;
						verifyPasswordRef.current = null;
					}
				}
			);
		}
	}

	return (
		<div
			className={`account-settings__change-password-tab account-settings__mobile-tab-content ${
				displayThis ? 'account-settings__show-mobile-tab-content' : ''
			}`}
		>
			<h2 className="account-settings__tab-heading">Password</h2>
			<form
				onSubmit={onPasswordResetSubmit}
				onInvalid={(error) => {
					error.preventDefault();
					validateOldPassword();
					validateNewPassword();
					validateVerifyPassword();
				}}
			>
				<Input
					type="password"
					label="Current Password"
					errorText={
						responseInvalid
							? "Hmm, those passwords don't match. Try again?"
							: 'Please enter current password.'
					}
					onChange={(pass) => {
						setOldPassword(pass);
						setOldPasswordValid(true);
					}}
					required
					valid={isOldPasswordCorrect()}
					testId="resetPassword__old-password-input"
					inputRef={oldPasswordRef}
				/>
				<Input
					type="password"
					label="New Password"
					errorText="Please enter new password."
					onChange={(password) => {
						setNewPassword(password);
						setNewPasswordValid(true);
					}}
					required
					valid={newPasswordValid}
					testId="resetPassword__password-input"
					inputRef={newPasswordRef}
				/>
				<Input
					type="password"
					label="Confirm New Password"
					errorText="Passwords must match."
					onChange={(validatedPassword) => {
						setVerifyPassword(validatedPassword);
						setVerifyPasswordValid(true);
					}}
					required
					valid={verifyPasswordValid}
					testId="resetPassword__verify-password-input"
					inputRef={verifyPasswordRef}
				/>
				<button
					className="button__primary"
					data-testid="resetPassword__submit-button"
				>
					Update Password
				</button>
			</form>
		</div>
	);
}

export default PasswordUpdateTab;
