import { Link } from 'react-router-dom';

import Vibez5miley from '../Assets/Icons/vibez-5.png';
import { ROOT_PATH } from '../Routes/RouteConstants';

import './NotFoundErrorPage.scss';

function NotFoundErrorPage() {
	return (
		<div className="form-container error-page__container">
			<img
				src={Vibez5miley}
				alt=""
				role="presentation"
				className="error-page__icon"
			/>
			<h1>Oops!</h1>
			<p className="error-page__text">
				we can't find the page you're looking for.
			</p>
			<Link to={ROOT_PATH} className="button__primary">
				Go Home
			</Link>
		</div>
	);
}

export default NotFoundErrorPage;
