import { useEffect } from 'react';
import { decodeToken } from 'react-jwt';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Header from '../Header/Header';
import Modal from '../Modal/Modal';
import { ROOT_PATH } from '../Routes/RouteConstants';
import { RouteContents } from '../Routes/Routes';
import { MobileMenuIsOpenAtom } from '../State/MobileMenuIsOpenAtom';
import { ModalContentsAtom } from '../State/ModalContentsAtom';
import VibezToken from '../Types/VibezToken';
import useAuthenticationContext from '../Utils/hooks/useAuthenticationContext';
import useColorScheme from '../Utils/hooks/useColorScheme';
import useWebSocketMessageHandler from '../Utils/hooks/useWebSocketMessageHandler';
import Version from '../Version/Version';
import WebSocketService from '../WebSocket/WebSocketService';

import './App.scss';

function App() {
	const location = useLocation();
	const modalContents = useRecoilValue(ModalContentsAtom);
	const setMobileMenuIsOpen = useSetRecoilState(MobileMenuIsOpenAtom);
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const colorScheme = useColorScheme();
	const { isAuthenticated, accessToken } = useAuthenticationContext();
	const { websocketMessageHandler } = useWebSocketMessageHandler();

	const colorSchemeClass = `background--${colorScheme}`;
	const blurredClass = modalContents ? 'blurred' : '';
	const isCurrentlyAtRootPath = location.pathname === ROOT_PATH;
	const defaultSiteWidth = isCurrentlyAtRootPath ? '' : 'default-site-width';

	useEffect(() => {
		if (isAuthenticated) {
			WebSocketService.connect(() => {
				const decodedToken = decodeToken(
					accessToken as string
				) as VibezToken;
				const teamId = decodedToken ? decodedToken['team-id'] : null;
				if (teamId) {
					WebSocketService.subscribe(teamId, websocketMessageHandler);
				}
			});
		} else {
			WebSocketService.disconnect();
		}
	}, [isAuthenticated, accessToken, websocketMessageHandler]);

	useEffect(() => {
		setMobileMenuIsOpen(false);
		setModalContents(null);
	}, [setMobileMenuIsOpen, setModalContents, location.pathname]);

	return (
		<div role="main">
			<div
				className={`app ${colorSchemeClass} ${blurredClass}`}
				data-testid="app"
				aria-hidden={!!modalContents}
			>
				<Header />
				<main className={`app__main ${defaultSiteWidth}`}>
					<RouteContents />
					{isCurrentlyAtRootPath ? <></> : <Version />}
				</main>
			</div>
			{modalContents && <Modal>{modalContents}</Modal>}
		</div>
	);
}

export default App;
