import { FormEvent, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";

import { ReactComponent as SendArrow } from '../../../Assets/Iconify-Icons/fluent__send-20-filled.svg';
import RestService from "../../../RestService/RestService";
import { ChatAtom } from "../../../State/ChatAtom";

import './ChatWindow.scss'


function ChatWindow({className}: { className: string }) {
	const chatInput = useRef<HTMLInputElement | null>(null);
	const messagesWindow = useRef<HTMLDivElement | null>(null);
	const chatMessages = useRecoilValue(ChatAtom);

	function onSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		RestService.sendChat(chatInput.current!.value).then(() => chatInput.current!.value = '');
	}

	useEffect(() => {
		messagesWindow.current!.scrollTop = messagesWindow.current?.scrollHeight ?? 0;
	}, [chatMessages])

	return (
		<div className={`${className} chat-window form-container`}>
			<div className='chat-window__title'>Team Chat</div>
			<div className='chat-window__logging-div'
				 role="log"
				 ref={messagesWindow}
			>
				<ol className='chat-window__message-container'
					data-testid='chatWindow__message-container'
					aria-live='polite'
					aria-atomic='false'
				>
					{chatMessages.map((message, index) => {
						return <li key={`message-bubble-${index}`}
								   className='chat-window__message-bubble'>
							{message}
						</li>
					})}
				</ol>
			</div>
			<div>
				<form onSubmit={onSubmit} className="chat-window__form">
					<div className="chat-window__text-and-button-wrapper">
						<input
							className={`chat-window__text-input`}
							placeholder="Enter chat message here"
							ref={chatInput}
							data-testid={'chatWindow__text-input'}
							maxLength={1000}
						/>
						<button
							data-testid={'chatWindow__submit-button'}
							className="chat-window__submit-button" type='submit'
							aria-label="Send"
						>
							<SendArrow className="chat-window__send-arrow"/>
						</button>
					</div>

				</form>
				<p className={`chat-window__disclaimer`}>
					Note: Chat messages are not archived and will disappear once you've ended discussion
				</p>
			</div>
		</div>
	)
}

export default ChatWindow;
