import FAQ from '../FAQ/FAQ';

import BannerSection from './BannerSection/BannerSection';
import ContactUsSection from './ContactUsSection/ContactUsSection';
import HowItWorksSection from './HowItWorksSection/HowItWorksSection';
import WhatIsAHealthCheckSection from './WhatIsAHealthCheckSection/WhatIsAHealthCheckSection';
import WhyVibezSection from './WhyVibezSection/WhyVibezSection';

import './LandingPage.scss';

function LandingPage() {
	return (
		<div className="landing-page">
			<BannerSection />
			<WhatIsAHealthCheckSection />
			<HowItWorksSection />
			<WhyVibezSection />
			<FAQ title="FAQ" />
			<ContactUsSection />
		</div>
	);
}

export default LandingPage;
