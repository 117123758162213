import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import AddActionItemButton from '../ActionItemComponents/AddActionItemButton';
import { ReactComponent as CheckedCheckbox } from '../Assets/Iconify-Icons/bx__checkbox-checked.svg';
import ActionItemModal from '../SessionResultsPage/ActionItemModal/ActionItemModal';
import { ActionItemsAtom } from '../State/ActionItemsAtom';
import { ModalContentsAtom } from '../State/ModalContentsAtom';
import { RecentlyMarkedDoneActionItemIdsAtom } from '../State/RecentlyMarkedDoneActionItemIdsAtom';
import useGetSession from '../Utils/hooks/useGetSession';

import ActionItemCard from './ActionItemCard/ActionItemCard';

import './ActionItemsPage.scss';

function ActionItemsPage() {
	const actionItems = useRecoilValue(ActionItemsAtom);
	const session = useGetSession();
	const recentlyCompletedActionItems = useRecoilValue(
		RecentlyMarkedDoneActionItemIdsAtom
	);
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const searchParams = useLocation().search;
	const displayOnlyCompletedActionItems =
		new URLSearchParams(searchParams).get('completed') === 'true';

	const NoActionsCard = () => (
		<div
			className="action-items__no-items-card form-container"
			role="alert"
		>
			<CheckedCheckbox
				role="presentation"
				className="action-items__no-items-icon"
			/>
			<h2 className="action-items__no-items-text">
				{displayOnlyCompletedActionItems
					? 'No action items completed yet!'
					: 'There are no open action items right now.'}
			</h2>
		</div>
	);

	function addActionItemButtonClickHandler() {
		setModalContents(<ActionItemModal />);
	}

	let actionItemsToDisplay = actionItems.filter(
		(actionItem) =>
			!actionItem.isDone ||
			recentlyCompletedActionItems.includes(actionItem.id)
	);
	if (displayOnlyCompletedActionItems) {
		actionItemsToDisplay = actionItems.filter(
			(actionItem) => actionItem.isDone
		);
	}

	return (
		<div className="action-items">
			<div className="action-items__banner">
				<h1 className="action-items__title">Action Items</h1>
				{session && (
					<AddActionItemButton
						className="button__style-inverted"
						onClickHandler={addActionItemButtonClickHandler}
					/>
				)}
			</div>
			{actionItemsToDisplay.length ? (
				<ul className="action-items__cards">
					{actionItemsToDisplay.map((actionItem) => (
						<li key={`action-item-${actionItem.id}`}>
							<ActionItemCard
								actionItem={actionItem}
								opaqueContent={
									!displayOnlyCompletedActionItems &&
									actionItem.isDone
								}
								disableModificationButtons={actionItem.isDone}
							/>
						</li>
					))}
				</ul>
			) : (
				<NoActionsCard />
			)}
		</div>
	);
}

export default ActionItemsPage;
