import {FormEvent} from 'react';
import {useSetRecoilState} from 'recoil';

import {Prompt} from '../../RestService/Models/Prompt';
import {PromptRequest} from '../../RestService/Models/PromptRequest';
import RestService from '../../RestService/RestService';
import {ModalContentsAtom} from '../../State/ModalContentsAtom';
import {UpdatePromptsSelector} from '../../State/PromptsAtom';

import './PromptModal.scss';

interface FormElements extends HTMLFormControlsCollection {
	promptText: HTMLInputElement;
}

interface AddPromptForm extends HTMLFormElement {
	readonly elements: FormElements;
}

interface Props {
	prompt?: Prompt;
}

function PromptModal({prompt}: Props) {
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const setPrompts = useSetRecoilState(UpdatePromptsSelector(prompt?.id));

	const addNewPrompt = (addPromptRequest: PromptRequest) => {
		RestService.createPrompt(addPromptRequest)
			.then((createdPrompt) => {
				setModalContents(null);
				setPrompts(createdPrompt);
			})
			.catch(console.error);
	};

	const editPrompt = (promptId: string, editPromptRequest: PromptRequest) => {
		RestService.editPrompt(promptId, editPromptRequest)
			.then((editedPrompt) => {
				setModalContents(null);
				setPrompts(editedPrompt);
			})
			.catch(console.error);
	};

	const savePrompt = (event: FormEvent<AddPromptForm>) => {
		event.preventDefault();

		const form = event.currentTarget.elements;
		const promptText = form.promptText.value;

		if (!!prompt) {
			editPrompt(prompt.id, {promptText});
		} else {
			addNewPrompt({promptText});
		}
	};

	const title = prompt ? 'Edit custom prompt' : 'New custom prompt';
	return (
		<form
			className="form-container prompt-modal"
			data-testid="promptModal__form"
			onSubmit={savePrompt}
		>
			<h1 className="prompt-modal__title">{title}</h1>
			<label className="input__label">
				Prompt text:
				<textarea
					className="prompt-modal__prompt-textarea"
					data-testid="promptModal__prompt-textarea"
					aria-label="Write your prompt text here"
					id="promptText"
					defaultValue={prompt?.promptText}
					maxLength={255}
				/>
			</label>
			<div className="prompt-modal__button-container">
				<button
					onClick={() => setModalContents(null)}
					className="prompt-modal__button button__secondary"
					data-testid="promptModal__cancel-button"
				>
					Cancel
				</button>
				<button
					type="submit"
					className="prompt-modal__button button__primary"
					data-testid="promptModal__save-button"
				>
					Save
				</button>
			</div>
		</form>
	);
}

export default PromptModal;
