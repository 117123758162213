import { useCallback, useEffect, useState } from 'react';
import { useJwt } from 'react-jwt';
import { useRecoilState } from 'recoil';

import { AccessTokenAtom } from '../../State/AccessTokenAtom';

interface AuthenticationContext {
	isAuthenticated: boolean;
	accessToken: string | null;
	setAccessToken: Function;
}

function useAuthenticationContext(): AuthenticationContext {
	const [accessToken, setAccessToken] = useRecoilState(AccessTokenAtom);
	const { isExpired } = useJwt(accessToken || '');
	const decideIsAuthenticated = useCallback(
		() => !!accessToken && !isExpired,
		[accessToken, isExpired]
	);
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
		decideIsAuthenticated()
	);

	useEffect(() => {
		setIsAuthenticated(decideIsAuthenticated());
	}, [isExpired, accessToken, decideIsAuthenticated]);

	useEffect(() => {
		if (isExpired) setAccessToken('');
	}, [isExpired, setAccessToken]);

	return { isAuthenticated, accessToken, setAccessToken };
}

export default useAuthenticationContext;
