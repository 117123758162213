import { Link } from 'react-router-dom';

import { ReactComponent as StartNewHealthCheckImage } from '../Assets/Home/start_new_health_check.svg';
import { SELECT_PROMPTS_PATH } from '../Routes/RouteConstants';

function LeadNewSessionPage() {
	return (
		<div className="form-container lead-new-session">
			<h1>Lead a New Health&nbsp;Check</h1>
			<StartNewHealthCheckImage
				className="home-page__health-check-image"
				role="presentation"
			/>
			<h2>Start a brand new health check.</h2>
			<Link
				to={SELECT_PROMPTS_PATH}
				className="button__primary"
				data-testid="homePage__create-new-btn"
			>
				Create New
			</Link>
		</div>
	);
}

export default LeadNewSessionPage;
