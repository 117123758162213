import { atom, RecoilState, selectorFamily } from 'recoil';

import { Prompt } from '../RestService/Models/Prompt';

export const PromptsAtom = atom<Array<Prompt>>({
	key: 'promptsAtom',
	default: [],
});

export const UpdatePromptsSelector: (
	actionItemId: string | undefined
) => RecoilState<Prompt | undefined> = selectorFamily({
	key: 'UpdatePromptsSelector',
	get:
		(promptId: string | undefined) =>
		({ get }): Prompt | undefined => {
			return get(PromptsAtom).find((item) => item.id === promptId);
		},
	set:
		(promptId: string | undefined) =>
		({ set, get }, newPrompt) => {
			let isUpdating = false;
			let updatedPrompts = get(PromptsAtom).map((item) => {
				if (item.id === promptId) {
					isUpdating = true;
					return newPrompt;
				} else {
					return item;
				}
			}) as Array<Prompt>;

			if (!isUpdating) {
				updatedPrompts = [...updatedPrompts, newPrompt as Prompt];
			}

			set(PromptsAtom, updatedPrompts);
		},
});
