import React from "react";

import image1 from '../../Assets/LandingPage/how-it-works-1.png';
import image2 from '../../Assets/LandingPage/how-it-works-2.png';
import image3 from '../../Assets/LandingPage/how-it-works-3.png';
import image4 from '../../Assets/LandingPage/how-it-works-4.png';

import Card from './Card/Card';

import './HowItWorksSection.scss';

function HowItWorksSection(): React.ReactElement {
	return (
		<section className="how-it-works-section">
			<h1 className="how-it-works-section__title">How it works</h1>
			<ol className="how-it-works-section__cards">
				<Card image={image1} number={1}>
					Choose a team member to be the facilitator.
				</Card>
				<Card image={image2} number={2}>
					Share honest, anonymous feedback by answering 8 prompts at
					your own pace.
				</Card>
				<Card image={image3} number={3}>
					When everyone is done, the facilitator should share their
					screen and lead a group discussion of the results.
				</Card>
				<Card image={image4} number={4}>
					Capture action items based on this discussion to identify
					next steps.
				</Card>
			</ol>
		</section>
	);
}

export default HowItWorksSection;
