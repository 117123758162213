import { useNavigate } from 'react-router-dom';

import Vibez5miley from '../Assets/Icons/vibez-5.png';
import { CONTACT_US_PATH } from '../Routes/RouteConstants';

import './ServerErrorPage.scss';

function ServerErrorPage() {
	const navigate = useNavigate();

	function handleGoBack() {
		navigate(-1);
	}

	return (
		<div className="form-container error-page__container">
			<img
				src={Vibez5miley}
				alt=""
				role="presentation"
				className="error-page__icon"
			/>
			<div className="error-page__content">
				<h1 className="server-error-page__header">Whoops!</h1>
				<p className="error-page__text">
					Something went wrong on our end. Try again, or{' '}
					<a
						className="server-error-page__content-link"
						data-testid="serverErrorPage__contact-us-button"
						href={CONTACT_US_PATH}
					>
						contact us
					</a>{' '}
					if the problem persists.
				</p>
				<button
					onClick={handleGoBack}
					data-testid="serverErrorPage__go-back-button"
					className="button__primary server-error-page__go-back-button"
				>
					Go Back and Try Again
				</button>
			</div>
		</div>
	);
}

export default ServerErrorPage;
