import React from "react";

import './Card.scss';

interface Props {
	image: string;
	number: number;
	children: string;
}

function Card({ children, image: imagePath, number }: Props): React.ReactElement {
	return (
		<li className="form-container card__container">
			<div className="card__circle" aria-label={`Step ${number}`}>
				{number}
			</div>
			<img src={imagePath} alt="" />
			<p>{children}</p>
		</li>
	);
}

export default Card;
