import DateService from '../Utils/DateService';

import './ConfirmHealthCheckSessionDialog.scss';

interface Props {
	joinActiveSession: () => void;
	startANewSession: () => void;
	activeSessionDate: string;
}

function ConfirmHealthCheckSessionDialog({
	joinActiveSession,
	startANewSession,
	activeSessionDate,
}: Props) {
	const getFormattedDate = () => {
		try {
			const formattedUtcDateTime = DateService.getFormattedDate(
				activeSessionDate,
				'MMMM D, YYYY, h:mmA'
			);
			return ` (began ${formattedUtcDateTime})`;
		} catch (e) {
			console.error(e);
			return '';
		}
	};

	return (
		<div className="form-container confirm-health-check-session-dialog">
			<h1>Heads Up!</h1>
			<p>
				It looks like you have a health check already in progress
				{getFormattedDate()}.
				<br />
				<br />
				Are you sure you want to{' '}
				<strong>
					save and end the current health check for your team?
				</strong>
			</p>
			<button
				data-testid="confirmHealthCheckSessionDialog__join-in-progress-button"
				className="button__primary"
				onClick={joinActiveSession}
			>
				No, join the session
			</button>
			<button
				data-testid="confirmHealthCheckSessionDialog__end-session-btn"
				className="button__secondary"
				onClick={startANewSession}
			>
				Yes, end the session
			</button>
		</div>
	);
}

export default ConfirmHealthCheckSessionDialog;
