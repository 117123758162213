import React from "react";

import { ReactComponent as UncheckedDoneIcon } from '../../Assets/Iconify-Icons/bx__checkbox.svg';
import { ReactComponent as CheckedDoneIcon } from '../../Assets/Iconify-Icons/bx__checkbox-checked.svg';

import './SkipSelectPromptsCheckbox.scss';

function SkipSelectPromptsCheckbox({onToggle, checked} : {onToggle: Function, checked: boolean}): React.ReactElement {
	return (
		<button
			className={`skip-select-prompts-button ${
				checked ? 'checked' : 'unchecked'
			}`}
			data-testid="skipSelectPromptsCheckbox"
			onClick={() => onToggle()}
			role="checkbox"
			aria-checked={checked}
		>
			{checked ? (
				<CheckedDoneIcon
					role="presentation"
					className="checkbox-icon"
				/>
			) : (
				<UncheckedDoneIcon
					role="presentation"
					className="checkbox-icon"
				/>
			)}
			<div className="skip-select-prompts-button__skip-to-results-text">
				<p className="skip-select-prompts-button__text1">I’m just here to facilitate, I’m not on this team.</p>
				<p className="skip-select-prompts-button__text2">(Skip voting and go straight to the results)</p>
			</div>
		</button>
	);
}

export default SkipSelectPromptsCheckbox;
