import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { ReactComponent as ActiveHealthCheckImage } from '../Assets/Home/active_health_check.svg';
import ConfirmHealthCheckSessionDialog from '../ConfirmHealthCheckSessionDialog/ConfirmHealthCheckSessionDialog';
import RestService from '../RestService/RestService';
import { PROMPTS_PATH, sessionResultPath } from '../Routes/RouteConstants';
import { ModalContentsAtom } from '../State/ModalContentsAtom';
import { SessionAtom } from '../State/SessionAtom';
import { SubmissionState, UserAnswersAtom } from '../State/UserAnswersAtom';
import DateService from '../Utils/DateService';

import './JoinActiveSessionPage.scss';

function JoinActiveSessionPage(): React.ReactElement {
	const navigate = useNavigate();
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const [currentSession, setCurrentSession] = useRecoilState(SessionAtom);
	const [userAnswers] = useRecoilState(UserAnswersAtom);

	const getActiveSessionDate = (): string | undefined => {
		if (currentSession?.dateStarted) {
			return DateService.getFormattedDate(
				currentSession.dateStarted,
				'MMMM D, YYYY [at] h:mma'
			);
		}
		return '';
	};

	const archiveAndStartNew = () => {
		setModalContents(
			<ConfirmHealthCheckSessionDialog
				activeSessionDate={currentSession?.dateStarted ?? ''}
				joinActiveSession={joinSession}
				startANewSession={() => {
					RestService.endSession(false)
						.then(() => {
							setCurrentSession(null);
							setModalContents(null);
						})
						.catch(console.error);
				}}
			/>
		);
	};

	const joinSession = () => {
		if (userAnswers.submissionState === SubmissionState.SUBMITTED && currentSession) {
			navigate(sessionResultPath(currentSession?.id));
		} else {
			navigate(PROMPTS_PATH);
		}
	};

	return (
		<>
			<div className="form-container join-existing-session">
				<h1 className="join-active-session__title">Join Your Team's Health&nbsp;Check</h1>
				<h2 className="join-active-session__created-date">(created {getActiveSessionDate()})</h2>
				<ActiveHealthCheckImage
					className="home-page__health-check-image"
					role="presentation"
				/>
				<button className="button__primary" onClick={joinSession}>
					Join In-Progress
				</button>
				{currentSession &&
					<Link to={`${sessionResultPath(currentSession!.id)}`}
						  className="button__secondary"
						  data-testid="joinActiveSessionPage__view-results-link">
						Just view the results
					</Link>
				}
			</div>
			<button
				onClick={archiveAndStartNew}
				className="home-page__archive-link"
				data-testid="homePage__archiveLink"
			>
				Archive Results and Start New
			</button>

		</>
	);
}

export default JoinActiveSessionPage;
