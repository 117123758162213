import { ReactNode } from 'react';

import DateService from '../Utils/DateService';

import './Banner.scss';

interface Props {
	title: string;
	date: string;
	subText?: string;
	children?: ReactNode;
}

function Banner({ title, date = '', subText, children }: Props) {
	const getDate = (dateStarted: string) => {
		return DateService.getFormattedDate(
			dateStarted,
			'MMMM D, YYYY - h:mmA'
		);
	};

	return (
		<div className="banner">
			<span
				className="banner__date-label"
				data-testid="banner__date-label"
			>
				Health Check – {getDate(date) ?? ''}
			</span>
			<div className="banner__content">
				<div>
					<h1 data-testid="banner__title" className="banner__title">
						{title}
					</h1>
					{subText && (
						<p className="banner__description">{subText}</p>
					)}
				</div>
				<div>{children}</div>
			</div>
		</div>
	);
}

export default Banner;
