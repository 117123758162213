import React, {FormEvent, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import Input from '../Input/Input';
import RestService from '../RestService/RestService';
import {LOGIN_PATH, PASSWORD_CHANGED_CONTEXT} from '../Routes/RouteConstants';

function ChangePasswordPage() {
	const [password, setPassword] = useState<string>('');
	const [confirmedPassword, setConfirmedPassword] = useState<string>('');
	const [passwordInputIsValid, setPasswordInputIsValid] =
		useState<boolean>(true);
	const token = useQuery().get('token');
	const navigate = useNavigate();

	function useQuery() {
		const { search } = useLocation();

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	const validatePasswords = () => {
		if (password && confirmedPassword) {
			let validationCheck = password === confirmedPassword;
			setPasswordInputIsValid(validationCheck);
			return validationCheck;
		} else {
			setPasswordInputIsValid(false);
			return false;
		}
	};

	const onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		let finalValidationCheck = validatePasswords();
		if (password && finalValidationCheck && token) {
			RestService.changePassword(token, password)
				.then(() =>
					navigate(
						LOGIN_PATH + '?context=' + PASSWORD_CHANGED_CONTEXT
					)
				)
				.catch(console.error);
		}
	};

	return (
		<form className="form-container" onSubmit={onSubmit}>
			<h1>Create New Password</h1>
			<Input
				type="password"
				label="Password"
				testId="changePasswordForm__password"
				errorText="Password is required and must match confirmation."
				onChange={setPassword}
				valid={passwordInputIsValid}
				onBlur={validatePasswords}
			/>
			<Input
				type="password"
				label="Confirm Password"
				testId="changePasswordForm__confirm-password"
				errorText="Password is required and must match."
				onChange={setConfirmedPassword}
				valid={passwordInputIsValid}
				onBlur={validatePasswords}
			/>
			<button
				className="button__primary"
				data-testid="changePassword__submit-button"
			>
				Reset Password
			</button>
		</form>
	);
}

export default ChangePasswordPage;
