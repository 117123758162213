import CookieService from '../CookieService/CookieService';

import WebSocketController from './WebSocketController';

const client = WebSocketController.getClient();

const WebSocketService = {
	connect: (onConnect: Function): void => {
		client.onConnect = () => {
			onConnect();
		};
		client.activate();
	},
	disconnect: (): void => {
		client.deactivate().then(() => {
			console.log('Deactivation successful!');
		});
	},
	subscribe: (teamId: string, webSocketMessageHandler: any): void => {
		const token = CookieService.getToken();
		const destination = '/topic/event/' + teamId;
		client.subscribe(destination, webSocketMessageHandler, {
			Authorization: `Bearer ` + token,
		});
	},
};

export default WebSocketService;
