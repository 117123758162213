import './SessionResultsCardSecondaryNav.scss';

interface Props {
	toggleFunction: Function;
	showSessionResultsCardTeamChatOnMobile: boolean;
}

function SessionResultsCardSecondaryNav({toggleFunction, showSessionResultsCardTeamChatOnMobile}: Props) {
	return (
		<div className="session_results_card__secondary-nav-container">
			<button
				data-testid="sessionResultsCard__secondary-nav-comments"
				onClick={() => showSessionResultsCardTeamChatOnMobile ? toggleFunction() : () => {
				}}
				className={`session_results_card__toggle-link  ${
					showSessionResultsCardTeamChatOnMobile ? '' : 'is-active'
				}`}
				aria-label="review responses and comments"
			>
				Comments
			</button>
			<button
				data-testid="sessionResultsCard__secondary-nav-team-chat"
				onClick={() => showSessionResultsCardTeamChatOnMobile ? () => {
				} : toggleFunction()}
				className={`session_results_card__toggle-link  ${
					showSessionResultsCardTeamChatOnMobile ? 'is-active' : ''
				}`}
				aria-label="chat about prompt"
			>
				Chat
			</button>
		</div>
	);
}

export default SessionResultsCardSecondaryNav;
