import React from 'react';
import { useRecoilValue } from 'recoil';

import EmoticonImage from '../../Emoticon/EmoticonImage';
import { emoticonMap } from '../../Emoticon/EmoticonMap';
import { SessionAtom } from '../../State/SessionAtom';
import { ShowResponsesAtom } from '../../State/ShowResponsesAtom';
import { AnswerForPromptSelector } from '../../State/UserAnswersAtom';
import SessionResultCard from '../SessionResultCard/SessionResultCard';
import { SinglePromptResultsProps } from '../SinglePromptResultsProps';

import './CollapsedSinglePromptResult.scss';

function CollapsedSinglePromptResult({
	prompt,
	answersForPrompt,
	onClick,
}: SinglePromptResultsProps) {
	const promptId = prompt.id;
	const myAnswerForPrompt = useRecoilValue(AnswerForPromptSelector(promptId));
	const highlightUserResponse = useRecoilValue(ShowResponsesAtom);
	const session = useRecoilValue(SessionAtom);
	const showResponses = useRecoilValue(ShowResponsesAtom);
	let myVoteWasFound = false;

	const getVotesDescription = (): string => {
		const votesForBad = answersForPrompt.filter(
			(prompt) => prompt.vibeValue === 1
		).length;
		const votesForEh = answersForPrompt.filter(
			(prompt) => prompt.vibeValue === 2
		).length;
		const votesForOk = answersForPrompt.filter(
			(prompt) => prompt.vibeValue === 3
		).length;
		const votesForGood = answersForPrompt.filter(
			(prompt) => prompt.vibeValue === 4
		).length;
		const votesForGreat = answersForPrompt.filter(
			(prompt) => prompt.vibeValue === 5
		).length;

		let ariaLabel = `There are ${votesForBad} vote for bad,
		 ${votesForEh} votes for meh,
		  ${votesForOk} votes for ok,
		   ${votesForGood} votes for good, ${votesForGreat} votes for great.`;

		if (showResponses && myAnswerForPrompt?.vibeValue) {
			ariaLabel += ` My vote was: ${
				emoticonMap[myAnswerForPrompt?.vibeValue]
			}.`;
		}

		return ariaLabel;
	};

	const thisIsMyVote = (vibeValue: number): boolean => {
		const myVoteForPrompt = myAnswerForPrompt?.vibeValue;
		const vibeValuesMatch = myVoteForPrompt === vibeValue;
		const sessionIsActive = !session?.dateCompleted;
		if (vibeValuesMatch && !myVoteWasFound && sessionIsActive) {
			myVoteWasFound = true;
			return myVoteWasFound;
		}
		return false;
	};

	const commentCount = (): number =>
		answersForPrompt.filter((prompt) => !!prompt.comment).length;

	return (
		<SessionResultCard
			prompt={prompt}
			dataTestId={`sessionResultCard__id-${promptId}`}
			commentsButtonText={`Comments (${commentCount()})`}
			onClick={onClick}
			isExpanded={false}
		>
			<>
				<div
					className="only-visible-to-screen-reader"
					data-testid={`sessionResult__votes-description-${promptId}`}
				>
					{getVotesDescription()}
				</div>
				<div
					className="collapsed-single-prompt-result__votes"
					data-testid="sessionResults__votes"
				>
					{answersForPrompt.map(({ vibeValue }, index) => {
						return (
							<React.Fragment
								key={`emoticonImage-${promptId}-${index}`}
							>
								<EmoticonImage
									value={vibeValue}
									grayedOut={false}
									className={
										highlightUserResponse &&
										thisIsMyVote(vibeValue)
											? 'green-border'
											: undefined
									}
								/>
							</React.Fragment>
						);
					})}
				</div>
			</>
		</SessionResultCard>
	);
}

export default CollapsedSinglePromptResult;
