import { Client } from '@stomp/stompjs';

import CookieService from '../CookieService/CookieService';

const url = window.location.hostname.includes('localhost')
	? 'ws://localhost:8080/api/event/websocket'
	: 'wss://' + window.location.hostname + '/api/event/websocket';

const getClient = () =>
	new Client({
		brokerURL: url,
		connectHeaders: {
			Authorization: `Bearer ` + CookieService.getToken(),
		},
		onDisconnect: () => {},
		onWebSocketError: () => {},
		onStompError: () => {},
		reconnectDelay: 3000,
		heartbeatIncoming: 4000,
		heartbeatOutgoing: 4000,
	});

const WebSocketController = {
	getClient,
};

export default WebSocketController;
