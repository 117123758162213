import React from "react";
import { Link } from 'react-router-dom';

import { HOME_PATH } from '../Routes/RouteConstants';

function NoSessionsFound(): React.ReactElement {
	return (
		<div role="alert" className="history-page__no-content form-container">
			<h1 className="history-page__no-content-header">
				No results to show.
			</h1>
			<p className="history-page__no-content-text">
				It looks like you haven't completed a team health check yet.
				Would you like to start your first one now?
			</p>
			<Link
				data-testid="historyPage__history-empty-start-new"
				className="button__primary"
				to={HOME_PATH}
			>
				NEW HEALTH CHECK
			</Link>
		</div>
	);
}

export default NoSessionsFound;
