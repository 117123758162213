import React, {useState} from 'react';

import Accordion from './Accordion/Accordion';

import './FAQ.scss';

const DEFAULT_EXPANDED_INDEX = 0;

interface Props {
	title: string;
}

function FAQ({ title }: Props): React.ReactElement {
	const [expandedIndex, setExpandedIndex] = useState<number>(
		DEFAULT_EXPANDED_INDEX
	);

	const expandOrCollapseAccordion = (index: number) => {
		const newExpandedIndex =
			expandedIndex === index ? DEFAULT_EXPANDED_INDEX : index;
		setExpandedIndex(newExpandedIndex);
	};

	return (
		<div className="faq-page" id="faq">
			<h1 className="faq-page__title">{title}</h1>
			<div className="faq-page__content">
				<div className="faq-page__content-column">
					<Accordion
						index={1}
						isExpanded={expandedIndex === 1}
						onClick={expandOrCollapseAccordion}
						title="Why would we want to do this?"
					>
						<p>
							Team health checks focus on specific topics, usually
							long-term, that may benefit from team discussion.
							The ultimate goal is open, honest communication to
							generate actionable insights.
							<br />
							<br />
							(Also, don’t forget to celebrate your wins!)
						</p>
					</Accordion>
					<Accordion
						index={3}
						isExpanded={expandedIndex === 3}
						onClick={expandOrCollapseAccordion}
						title="Should I do this alone or with my team?"
					>
						<p>
							You and your team members will individually and
							independently respond to the 8 prompts given. Once
							everyone’s answers are in, you’ll come together to
							view and discuss the results as a team. Whether you
							choose to have everyone answer the prompts ahead of
							time, or as the first part of your team health check
							meeting, that’s up to you and your team.
						</p>
					</Accordion>
					<Accordion
						index={5}
						isExpanded={expandedIndex === 5}
						onClick={expandOrCollapseAccordion}
						title="How anonymous are my responses?"
					>
						<p>
							Completely anonymous. What do we mean by that? A
							team login (vs. an individual login, like your
							cdsid) removes the ability to identify you. We don’t
							keep track of your IP address, MAC address, or any
							other identifying information to distinguish who
							votes what. You may verbally offer that info to your
							team during the discussion: “Yeah, that really
							unhappy vote was me. Here’s why I feel that way...”
						</p>
					</Accordion>
					<Accordion
						index={7}
						isExpanded={expandedIndex === 7}
						onClick={expandOrCollapseAccordion}
						title="Who can see our answers?"
					>
						<p>
							The individual votes for each item are visible only
							to your team (and only you will see what you voted,
							on your own device).
							<br />
							<br />
							If you’re the facilitator, your vote will not be
							revealed on your own screen (this way, you can share
							your screen during the team discussion without
							losing anonymity).
							<br />
							<br />
							We want to make sure teams feel safe and able to be
							honest, and anything that compromises that is
							something we want to change (seriously) so let us
							know if we can improve in any way!
						</p>
					</Accordion>
				</div>
				<div className="faq-page__content-column">
					<Accordion
						index={2}
						isExpanded={expandedIndex === 2}
						onClick={expandOrCollapseAccordion}
						title="How often should we do this?"
					>
						<p>
							We’d recommend occasionally replacing your
							traditional retro with a health check—once a month,
							once a quarter, or somewhere in between, whatever
							feels right for your team. (Try something out — you
							can always change the frequency later!)
						</p>
					</Accordion>
					<Accordion
						index={4}
						isExpanded={expandedIndex === 4}
						onClick={expandOrCollapseAccordion}
						title="How long will this take?"
					>
						<p>
							Plan for the entire process to take at least an
							hour. Answering the prompts will probably take
							around 5 minutes, but the discussion part can vary
							wildly! Try talking about your top 4-5 items for 10
							minutes each, to start. If you find you need more
							time, you could limit the number of items you
							discuss, or extend your health check to 90 minutes.
							Each team will probably have a unique combo that
							works for them, so experiment and find yours!
						</p>
					</Accordion>
					<Accordion
						index={6}
						isExpanded={expandedIndex === 6}
						onClick={expandOrCollapseAccordion}
						title="How should we do this?"
					>
						<p>
							Choose a team member to be the facilitator.
							<br />
							<br />
							Share honest, anonymous feedback by answering 8
							prompts at your own pace.
							<br />
							<br />
							When everyone is done, the facilitator should share
							their screen and lead a group discussion of the
							results.
							<br />
							<br />
							Capture action items based on this discussion to
							identify next steps.
						</p>
					</Accordion>
					<Accordion
						index={8}
						isExpanded={expandedIndex === 8}
						onClick={expandOrCollapseAccordion}
						title="How is this different than retroquest?"
					>
						<p>
							Retros typically focus on the last sprint and what
							went well or poorly with the product.
							<br />
							<br />
							Health checks look at a longer cadence, and focus on
							the well-being of the team and how team members are
							feeling: both about their role within the team, and
							about the product as a whole.
						</p>
					</Accordion>
				</div>
			</div>
		</div>
	);
}

export default FAQ;
