import { useRef } from 'react';
import FocusTrap from 'focus-trap-react';

import useOnClickOutside from '../../Utils/hooks/useOnClickOutside';

import './ActionItemConfirmDeleteOverlay.scss';

interface Props {
	onYesClicked: () => void;
	onNoClicked: () => void;
}

function ActionItemConfirmDeleteOverlay({ onYesClicked, onNoClicked }: Props) {
	const ref = useRef(null);

	useOnClickOutside(ref, onNoClicked);

	return (
		<FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
			<div
				ref={ref}
				className="action-item-card__overlay form-container action-item-confirm-delete-overlay__container"
			>
				<h1 className="action-item-confirm-delete-overlay__text">
					Delete this action item?
				</h1>
				<div className="action-item-confirm-delete-overlay__button-container">
					<button
						onClick={onNoClicked}
						data-testid="deleteActionItemOverlay__no-button"
						className="button__primary"
					>
						No
					</button>
					<button
						onClick={onYesClicked}
						data-testid="deleteActionItemOverlay__yes-button"
						className="button__secondary"
					>
						Yes
					</button>
				</div>
			</div>
		</FocusTrap>
	);
}

export default ActionItemConfirmDeleteOverlay;
