import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ConfirmHealthCheckSessionDialog from '../../ConfirmHealthCheckSessionDialog/ConfirmHealthCheckSessionDialog';
import { Session } from '../../RestService/Models/Session';
import RestService from '../../RestService/RestService';
import { PROMPTS_PATH, sessionResultPath } from '../../Routes/RouteConstants';
import { AccessTokenAtom } from '../../State/AccessTokenAtom';
import { ModalContentsAtom } from '../../State/ModalContentsAtom';
import {
	DEFAULT_USER_ANSWERS_STATE,
	UserAnswersAtom,
} from '../../State/UserAnswersAtom';

interface NewHealthCheckActions {
	onNewHealthCheck: (skipPrompts: boolean) => void;
}

function useNewHealthCheckActions(): NewHealthCheckActions {
	const accessToken = useRecoilValue(AccessTokenAtom);
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const setUserAnswers = useSetRecoilState(UserAnswersAtom);
	const navigate = useNavigate();

	const joinActiveSession = (skipToSessionId?:string) => {
		setModalContents(null);
		if(skipToSessionId) {
			navigate(sessionResultPath(skipToSessionId))
		}else {
			navigate(PROMPTS_PATH);
		}
	};

	const startANewSession = (skipPromptsPage: boolean) => {
		if (accessToken) {
			RestService.createSession(accessToken)
				.then((session) => {
					setUserAnswers(DEFAULT_USER_ANSWERS_STATE);
					joinActiveSession(skipPromptsPage ? session.id : undefined);
				})
				.catch(console.error);
		}
	};

	const onNewHealthCheck = (skipPromptsPage: boolean) => {
		RestService.getCurrentSession()
			.then((activeSession: Session) => {
				if (activeSession) {
					setModalContents(
						<ConfirmHealthCheckSessionDialog
							joinActiveSession={() => {
								joinActiveSession(skipPromptsPage? activeSession.id : undefined)
							}}
							startANewSession={() => {
								startANewSession(skipPromptsPage)
							}}
							activeSessionDate={activeSession.dateStarted}
						/>
					);
				} else {
					startANewSession(skipPromptsPage);
				}
			})
			.catch(console.error);
	};

	return {onNewHealthCheck};
}

export default useNewHealthCheckActions;
