import { atom, RecoilState, selector, selectorFamily } from 'recoil';

import { SingleAnonymousAnswerForPrompt } from '../RestService/Models/SingleAnonymousAnswerForPrompt';

import { SessionAtom } from './SessionAtom';

export enum SubmissionState {
	IN_PROGRESS,
	SUBMITTED,
}

export interface UserAnswersState {
	sessionId: string;
	answers: Array<SingleAnonymousAnswerForPrompt>;
	submissionState: SubmissionState;
}

export const DEFAULT_USER_ANSWERS_STATE: UserAnswersState = {
	sessionId: '',
	answers: [],
	submissionState: SubmissionState.IN_PROGRESS,
};

const DefaultUserAnswersSelector = selector({
	key: 'getDefaultUserAnswersSelector',
	get: ({ get }) => {
		const session = get(SessionAtom);
		const localStorageDefaults = session?.id
			? localStorage.getItem(session?.id)
			: null;
		return localStorageDefaults
			? JSON.parse(localStorageDefaults || '')
			: DEFAULT_USER_ANSWERS_STATE;
	},
});

export const UserAnswersAtom = atom<UserAnswersState>({
	key: 'userAnswersAtom',
	default: DefaultUserAnswersSelector,
	effects_UNSTABLE: [
		({ onSet }) => {
			onSet((userAnswersState) => {
				localStorage.clear();
				// @ts-ignore
				const sessionId = userAnswersState.sessionId;
				if (sessionId) {
					localStorage.setItem(
						sessionId,
						JSON.stringify(userAnswersState)
					);
				}
			});
		},
	],
});

export const AnswerForPromptSelector: (
	promptId: string
) => RecoilState<SingleAnonymousAnswerForPrompt | undefined> = selectorFamily({
	key: 'UpdateAnswerForPromptSelector',
	get:
		(promptId: string) =>
		({ get }): SingleAnonymousAnswerForPrompt | undefined => {
			const userAnswers = get(UserAnswersAtom).answers;
			return getAnswersForPrompt(userAnswers, promptId);
		},
	set:
		(promptId: string) =>
		({ set, get }, newAnswer) => {
			let updatingAnswer = false;
			const userAnswersState = get(UserAnswersAtom);
			let updatedUserAnswers = userAnswersState.answers.map(
				(userAnswer) => {
					if (userAnswer.promptId === promptId) {
						updatingAnswer = true;
						return newAnswer;
					} else {
						return userAnswer;
					}
				}
			) as Array<SingleAnonymousAnswerForPrompt>;

			if (!updatingAnswer) {
				updatedUserAnswers = [
					...updatedUserAnswers,
					newAnswer as SingleAnonymousAnswerForPrompt,
				];
			}
			const activeSession = get(SessionAtom);
			const updatedUserAnswersState: UserAnswersState = {
				...userAnswersState,
				sessionId: activeSession?.id!,
				answers: updatedUserAnswers,
			};
			set(UserAnswersAtom, updatedUserAnswersState);
		},
});

export function getAnswersForPrompt(
	userAnswers: Array<SingleAnonymousAnswerForPrompt>,
	promptId: string
): SingleAnonymousAnswerForPrompt | undefined {
	return userAnswers.find((answer) => answer.promptId === promptId);
}
