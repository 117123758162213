export const TOKEN_KEY = 'accessToken';

export const LOGIN_API_PATH = '/auth/login';
export const TEAMS_API_PATH = '/teams';
export const REQUEST_PASSWORD_RESET_PATH =
	TEAMS_API_PATH + '/request-password-reset';
export const REQUEST_RECOVER_TEAM_NAME_PATH = TEAMS_API_PATH + '/team-names';

export const SESSIONS_API_PATH = '/sessions';
export const CURRENT_SESSION_API_PATH = SESSIONS_API_PATH + '/current';
export const PROMPTS_API_PATH = '/prompts';
export const CURRENT_PROMPTS_API_PATH = '/prompts/current';
export const ANSWERS_API_PATH = '/answers';
export const ANSWERS_BY_SESSION_API_PATH = ANSWERS_API_PATH + '/sessions';
export const ACTION_ITEMS_API_PATH = '/action-items';
export const ACTION_ITEMS_BY_SESSION_API_PATH =
	ACTION_ITEMS_API_PATH + '/sessions';
export const ACTION_ITEMS_OF_ALL_SESSIONS_API_PATH =
	ACTION_ITEMS_BY_SESSION_API_PATH + '/all';
export const TEAM_CHANGE_PASSWORD_PATH = TEAMS_API_PATH + '/change-password';
export const FEEDBACK_API_URL = '/feedback';
export const CHAT_API_URL = '/chat';
export const TEAM_DETAILS_API_PATH = TEAMS_API_PATH + '/modify-team-details';
export const USER_RESET_PASSWORD_API_PATH =
	TEAMS_API_PATH + '/user-change-password';
