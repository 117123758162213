import React from "react";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { ReactComponent as AddPromptIcon } from '../Assets/Icons/prompts-add.svg';
import Banner from '../Banner/Banner';
import RestService from '../RestService/RestService';
import { HOME_PATH } from '../Routes/RouteConstants';
import { ModalContentsAtom } from '../State/ModalContentsAtom';
import { PromptsAtom } from '../State/PromptsAtom';
import useNewHealthCheckActions from '../Utils/hooks/useNewHealthCheckActions';

import PromptCard from './PromptCard/PromptCard';
import PromptModal from './PromptModal/PromptModal';
import SkipSelectPromptsCheckbox from "./SkipSelectPromptsCheckbox/SkipSelectPromptsCheckbox";

import './SelectPromptsPage.scss';

function SelectPromptsPage(): React.ReactElement {
	const [prompts, setPrompts] = useRecoilState(PromptsAtom);
	const [skipToResults, setSkipToResults] = useState(false);
	const todaysDate = new Date().toString();
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const {onNewHealthCheck} = useNewHealthCheckActions();

	useEffect(() => {
		if (prompts.length === 0) {
			RestService.getCurrentPrompts()
				.then(setPrompts)
				.catch(console.error);
		}
	}, [prompts, setPrompts]);

	const openPromptModal = () => {
		setModalContents(<PromptModal/>);
	};

	return (
		<div className="select-prompts-page">
			<Banner title="Your Team's Prompts:" date={todaysDate}/>
			<ul className="select-prompts-page__prompts-list">
				{prompts.map((prompt) => {
					return (
						<li key={`prompt-card-${prompt.id}`}>
							<PromptCard prompt={prompt}/>
						</li>
					);
				})}
				<li data-testid="selectPromptsPage__add-prompt-card">
					<button
						className="button__primary select-prompts-page__add-prompt-card-button"
						data-testid="selectPromptsPage__add-prompt-card-button"
						onClick={openPromptModal}
					>
						<AddPromptIcon
							role="presentation"
							className="select-prompts-page__add-prompt-icon"
						/>
						Add Custom Prompt
					</button>
				</li>
			</ul>
			<div className="select-prompts-page__skip-to-results">
				<SkipSelectPromptsCheckbox onToggle={() => {
					setSkipToResults((!skipToResults))
				}} checked={skipToResults}/>
			</div>
			<div className="select-prompts-page__footer">

				<Link
					to={HOME_PATH}
					className="button__secondary select-prompts-page__footer-cancel-link"
				>
					Cancel
				</Link>
				<button
					onClick={() => {onNewHealthCheck(skipToResults)}}
					data-testid="selectPromptsPage__start-this-health-check-btn"
					className="button__primary select-prompts-page__footer-start-health-check-btn"
				>
					Start this Health Check
				</button>
			</div>
		</div>
	);
}

export default SelectPromptsPage;
