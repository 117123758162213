import { FormEvent, useRef, useState } from 'react';

import EmailImage from '../Assets/PasswordReset/check_your_mail.svg';
import Input from '../Input/Input';
import RestService from '../RestService/RestService';

import './RecoverTeamNamePage.scss';

function RecoverTeamNamePage() {
	const [email, setEmail] = useState('');
	const [formIsValid, setFormIsValid] = useState(true);
	const [messageSent, setMessageSent] = useState<boolean>(false);
	const emailInputRef = useRef<HTMLInputElement>();

	const onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		RestService.requestRecoverTeamName(email)
			.then(() => setMessageSent(true))
			.catch(console.error);
	};

	return messageSent ? (
		<div className="form-container recover-team-name__acknowledge-container">
			<img
				className="recover-team-name__image"
				alt={''}
				src={EmailImage}
			/>
			<h1 className="recover-team-name__title">Check your mail!</h1>
			<p className="recover-team-name__text">
				If a team name exists with the email address below, you’ll
				receive it in your inbox shortly.
			</p>
			<h2 className="recover-team-name__email">{email}</h2>
		</div>
	) : (
		<form
			className="form-container recover-team-name-form__container"
			onSubmit={onSubmit}
			onInvalid={(e) => {
				e.preventDefault();
				setFormIsValid(emailInputRef.current!.validity.valid);
			}}
		>
			<h1 className="recover-team-name-form__title">Recover Team Name</h1>
			<p className="recover-team-name-form__text">
				Enter your email below, and we’ll send you the team name (or
				names!) associated with your account.
			</p>
			<Input
				label="Email address"
				type="email"
				testId="recoverTeamNameForm__email"
				errorText="Email is required."
				valid={formIsValid}
				onChange={(value) => {
					setEmail(value);
					setFormIsValid(true);
				}}
				inputRef={emailInputRef}
				required
			/>
			<button
				className="button__primary"
				data-testid="recoverTeamNameForm__submit-button"
			>
				Send me my team name
			</button>
		</form>
	);
}

export default RecoverTeamNamePage;
