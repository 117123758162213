import Emoticons from '../Assets/Emoticons';

import { emoticonMap } from './EmoticonMap';

interface Props {
	value: number;
	grayedOut: boolean;
	className?: string;
}

function EmoticonImage({value, grayedOut, className}: Props) {
	const wrapperTestId = (vibeValue: number) =>
		`emoticonImageWrapper--${emoticonMap[vibeValue]}${
			grayedOut ? '--gray' : ''
		}`;
	const wrapperClassName = `emoticon__image ${className || ''}`;

	switch (value) {
		case 1:
			return getBadEmoticon();
		case 2:
			return getEhEmoticon();
		case 3:
			return getOkEmoticon();
		case 4:
			return getGoodEmoticon();
		case 5:
			return getGreatEmoticon();
		default:
			return <>Error</>;
	}

	function getBadEmoticon() {
		const Icon = grayedOut ? Emoticons.BadIconGray : Emoticons.BadIcon;
		return (
			<Icon data-testid={wrapperTestId(1)} className={wrapperClassName} role="presentation"/>
		);
	}

	function getEhEmoticon() {
		const Icon = grayedOut ? Emoticons.EhIconGray : Emoticons.EhIcon;
		return (
			<Icon data-testid={wrapperTestId(2)} className={wrapperClassName} role="presentation"/>
		);
	}

	function getOkEmoticon() {
		const Icon = grayedOut ? Emoticons.OkIconGray : Emoticons.OkIcon;
		return (
			<Icon data-testid={wrapperTestId(3)} className={wrapperClassName} role="presentation"/>
		);
	}

	function getGoodEmoticon() {
		const Icon = grayedOut ? Emoticons.GoodIconGray : Emoticons.GoodIcon;
		return (
			<Icon data-testid={wrapperTestId(4)} className={wrapperClassName} role="presentation"/>
		);
	}

	function getGreatEmoticon() {
		const Icon = grayedOut ? Emoticons.GreatIconGray : Emoticons.GreatIcon;
		return (
			<Icon data-testid={wrapperTestId(5)} className={wrapperClassName} role="presentation"/>
		);
	}
}

export default EmoticonImage;
