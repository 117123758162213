import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import EmoticonRadioButton from '../../Emoticon/EmoticonRadioButton';
import { Prompt } from '../../RestService/Models/Prompt';
import {
	DEFAULT_COMMENT_VALUE,
	DEFAULT_VIBE_VALUE,
	SingleAnonymousAnswerForPrompt,
} from '../../RestService/Models/SingleAnonymousAnswerForPrompt';
import { AnswerForPromptSelector } from '../../State/UserAnswersAtom';

import './SinglePrompt.scss';

interface Props {
	prompt: Prompt;
}

const MAX_CHARS_FOR_COMMENT = 1000;

function SinglePrompt({ prompt }: Props) {
	const [answerForPrompt, setAnswerForPrompt] = useRecoilState(
		AnswerForPromptSelector(prompt.id)
	);
	const [emoticonValue, setEmoticonValue] =
		useState<number>(DEFAULT_VIBE_VALUE);
	const [comment, setComment] = useState<string | null>(
		DEFAULT_COMMENT_VALUE
	);

	useEffect(() => {
		const { vibeValue, comment }: SingleAnonymousAnswerForPrompt =
			answerForPrompt ?? ({} as SingleAnonymousAnswerForPrompt);
		setEmoticonValue(vibeValue ?? DEFAULT_VIBE_VALUE);
		setComment(comment ?? DEFAULT_COMMENT_VALUE);
	}, [prompt, answerForPrompt]);

	const selectedEmoticonChanged = (updatedEmoticonValue: number) => {
		setEmoticonValue(updatedEmoticonValue);
		setAnswerForPrompt({
			promptId: prompt.id,
			comment: comment ?? undefined,
			vibeValue: updatedEmoticonValue,
		});
	};

	function handleOnCommentChange(
		event: React.ChangeEvent<HTMLTextAreaElement>
	) {
		const updatedComment = event.target.value;
		if (getRemainingCharsForComment() > 0) {
			setComment(updatedComment);
			setAnswerForPrompt({
				promptId: prompt.id,
				comment: updatedComment,
				vibeValue: emoticonValue ?? DEFAULT_VIBE_VALUE,
			});
		}
	}

	const getRemainingCharsForComment = () => {
		const currentPromptCommentLength = comment?.length ?? 0;
		return MAX_CHARS_FOR_COMMENT - currentPromptCommentLength;
	};

	return (
		<>
			<div
				className="prompts__card form-container"
				data-testid="prompts__card"
			>
				<div className="prompts__card-category">{prompt.category}</div>
				<h2 className="prompts__card-statement">{prompt.promptText}</h2>
				<div className="prompts__card-emoticons">
					{[1, 2, 3, 4, 5].map((emoticonIndex) => {
						const shouldGrayOut =
							emoticonValue !== DEFAULT_VIBE_VALUE &&
							emoticonValue !== emoticonIndex;

						return (
							<EmoticonRadioButton
								key={`Emoticon${emoticonIndex}`}
								onEmoticonFocus={selectedEmoticonChanged}
								value={emoticonIndex}
								grayedOut={shouldGrayOut}
								selectedEmoticon={emoticonValue}
							/>
						);
					})}
				</div>
				<textarea
					placeholder="(Optional) Write your comments here"
					className="prompt__card-comments"
					data-testid="prompt__card-comments"
					aria-label="Optional, Write your comments here"
					value={comment ?? ''}
					onChange={handleOnCommentChange}
					maxLength={1000}
				/>
				<div className="prompt__card-comments-char-count">
					{`(Characters remaining: ${getRemainingCharsForComment()})`}
				</div>
			</div>
		</>
	);
}

export default SinglePrompt;
