import { useRecoilState, useSetRecoilState } from 'recoil';

import { ReactComponent as UncheckedDoneIcon } from '../../Assets/Iconify-Icons/bx__checkbox.svg';
import { ReactComponent as CheckedDoneIcon } from '../../Assets/Iconify-Icons/bx__checkbox-checked.svg';
import { Prompt } from '../../RestService/Models/Prompt';
import { ModalContentsAtom } from '../../State/ModalContentsAtom';
import { PromptsAtom } from '../../State/PromptsAtom';

interface Props {
	prompt: Prompt;
}

function SessionResultCardDoneButton({ prompt }: Props) {
	const { category, isDone } = prompt;
	const [prompts, setPrompts] = useRecoilState(PromptsAtom);
	const setModalContents = useSetRecoilState(ModalContentsAtom);

	const ariaLabel = `Mark ${category} prompt result card as ${
		isDone ? 'not ' : ''
	} done`;

	const updateIsDoneFlagForPrompt = (updatedIsDoneValue: boolean) => {
		const updatedPrompts: Array<Prompt> = [...prompts]
			.map((singlePrompt) => {
				if (prompt.id === singlePrompt.id) {
					return {
						...singlePrompt,
						isDone: updatedIsDoneValue,
					};
				}
				return singlePrompt;
			})
			.sort((prompt1, prompt2) => {
				return (
					Number(prompt1.isDone) - Number(prompt2.isDone) ||
					prompt1.category.localeCompare(prompt2.category)
				);
			});
		setPrompts(updatedPrompts);
		setModalContents(null);
	};

	return (
		<button
			className={`session-result-card__done-button ${
				isDone ? 'lightened-border' : ''
			}`}
			data-testid="sessionResultCard__done-button"
			aria-label={ariaLabel}
			onClick={() => updateIsDoneFlagForPrompt(!isDone)}
		>
			{isDone ? (
				<CheckedDoneIcon
					role="presentation"
					className="session-result-card__done-icon checked"
				/>
			) : (
				<UncheckedDoneIcon
					role="presentation"
					className="session-result-card__done-icon unchecked"
				/>
			)}
			Done
		</button>
	);
}

export default SessionResultCardDoneButton;
