import { useRef } from 'react';
import { useJwt } from 'react-jwt';
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { ReactComponent as LogInIconSVG } from '../../Assets/Iconify-Icons/bx__bx-log-in.svg';
import { ReactComponent as LogoutIconSVG } from '../../Assets/Iconify-Icons/bx__bx-log-out.svg';
import { ReactComponent as ActionItemIconSVG } from '../../Assets/Iconify-Icons/bx__checkbox-checked.svg';
import { ReactComponent as ContactUsIconSVG } from '../../Assets/Iconify-Icons/ci__mail.svg';
import { ReactComponent as FAQIconSVG } from '../../Assets/Icons/faq-icon.svg';
import { ReactComponent as HealthCheckIconSVG } from '../../Assets/Icons/health-check-icon.svg';
import { ReactComponent as ResultsIconSVG } from '../../Assets/Icons/results-icon.svg';
import {
	ACTION_ITEMS_PATH,
	CONTACT_US_PATH,
	FAQ_PATH,
	FEEDBACK_ROUTE,
	GOODBYE_PATH,
	HISTORICAL_RESULTS_PATH,
	HOME_PATH,
	LOGIN_PATH,
} from '../../Routes/RouteConstants';
import { AccessTokenAtom } from '../../State/AccessTokenAtom';
import { FeedbackOriginAtom } from '../../State/FeedbackOriginAtom';
import { MobileMenuIsOpenAtom } from '../../State/MobileMenuIsOpenAtom';
import useOnClickOutside from '../../Utils/hooks/useOnClickOutside';

import './Navigation.scss';
import './Navigation-dark.scss';
import './Navigation-light.scss';

function Navigation() {
	const [mobileMenuIsOpen, setMobileMenuIsOpen] =
		useRecoilState(MobileMenuIsOpenAtom);
	const setFeedbackIsFromEndOfSession = useSetRecoilState(FeedbackOriginAtom);
	const [accessToken, setAccessToken] = useRecoilState(AccessTokenAtom);
	const { isExpired } = useJwt(accessToken || '');
	const isAuthenticated = accessToken && !isExpired;
	const ref = useRef(null);

	useOnClickOutside(
		ref,
		() => {
			if (mobileMenuIsOpen) setMobileMenuIsOpen(false);
		},
		{ disable: !mobileMenuIsOpen }
	);

	const onLogout = () => {
		setAccessToken(null);
	};

	const determineFAQLink = () => {
		const url = isAuthenticated ? FAQ_PATH : '/#faq';

		const linkContent = (
			<>
				<FAQIconSVG
					role="presentation"
					className="navigation__item-icon"
				/>
				FAQ
			</>
		);

		const dataTestId = 'navigation__faq-link';
		return isAuthenticated ? (
			<NavLink
				data-testid={dataTestId}
				to={url}
				className={({ isActive }) =>
					'navigation__link' +
					(isActive && isAuthenticated ? ' is-active' : '')
				}
			>
				{linkContent}
			</NavLink>
		) : (
			<HashLink
				to={url}
				data-testid={dataTestId}
				className="navigation__link"
			>
				{linkContent}
			</HashLink>
		);
	};

	const FeedbackButton = ({
		className,
		label,
		dataTestId,
	}: {
		className: string;
		label: string;
		dataTestId: string;
	}) => {
		return (
			<Link
				to={FEEDBACK_ROUTE}
				onClick={() => {
					setFeedbackIsFromEndOfSession(false);
				}}
				className={`navigation__link ${className}`}
				aria-label="Help vibes improve by giving us feedback"
				data-testid={dataTestId}
			>
				{label}
			</Link>
		);
	};

	return (
		<nav className="navigation" ref={ref}>
			<h1 className="visually-hidden">Main Menu</h1>
			<FeedbackButton
				label="Feedback"
				className="navigation__feedback-link--mobile"
				dataTestId="navigation__feedback-link--mobile"
			/>
			<button
				data-testid="hamburgerButton"
				onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
				className="vibez-header__hamburger"
				aria-haspopup={true}
				aria-expanded={mobileMenuIsOpen}
				aria-controls="menu"
				aria-label={mobileMenuIsOpen ? 'Close Menu' : 'Open Menu'}
			>
				<div className="navigation__hamburger-menu-line" />
				<div className="navigation__hamburger-menu-line" />
				<div className="navigation__hamburger-menu-line" />
			</button>
			<ul className="navigation__menu" id="menu">
				{isAuthenticated && (
					<>
						<li className="navigation__item">
							<NavLink
								to={HOME_PATH}
								className={({ isActive }) =>
									'navigation__link' +
									(isActive ? ' is-active' : '')
								}
								data-testid="navigation__health-check-link"
							>
								<HealthCheckIconSVG
									role="presentation"
									className="navigation__item-icon"
								/>
								Health Check
							</NavLink>
						</li>
						<hr role="presentation" />
						<li className="navigation__item">
							<NavLink
								to={HISTORICAL_RESULTS_PATH}
								className={({ isActive }) =>
									'navigation__link' +
									(isActive ? ' is-active' : '')
								}
							>
								<ResultsIconSVG
									role="presentation"
									className="navigation__item-icon"
								/>
								Results
							</NavLink>
						</li>
						<hr role="presentation" />
						<li className="navigation__item">
							<NavLink
								to={ACTION_ITEMS_PATH}
								end
								className={({ isActive }) =>
									'navigation__link' +
									(isActive ? ' is-active' : '')
								}
							>
								<ActionItemIconSVG
									role="presentation"
									className="navigation__item-icon"
								/>
								Action Items
							</NavLink>
						</li>
					</>
				)}
				<hr role="presentation" />
				<li className="navigation__item">{determineFAQLink()}</li>
				<hr role="presentation" />
				<li className="navigation__item">
					<NavLink
						data-testid="navigation__help-navlink"
						to={CONTACT_US_PATH}
						className={({ isActive }) =>
							'navigation__link' + (isActive ? ' is-active' : '')
						}
					>
						<ContactUsIconSVG
							role="presentation"
							className="navigation__item-icon"
						/>
						Help
					</NavLink>
				</li>
				<hr role="presentation" />
				{isAuthenticated ? (
					<li className="navigation__item">
						<Link
							to={GOODBYE_PATH}
							className="navigation__logout-link"
							onClick={onLogout}
							data-testid="navigation__logout-link"
						>
							<LogoutIconSVG
								role="presentation"
								className="navigation__item-icon"
							/>
							Log Out
						</Link>
					</li>
				) : (
					<li className="navigation__item">
						<Link
							to={LOGIN_PATH}
							className="navigation__logout-link"
							data-testid="navigation__login-link"
						>
							<LogInIconSVG
								role="presentation"
								className="navigation__item-icon"
							/>
							Log In
						</Link>
					</li>
				)}
				<li className="navigation__item hide-on-tablet-and-mobile">
					<div className="navigation__feedback-link-container">
						<FeedbackButton
							label="Feedback"
							className="navigation__feedback-link--desktop"
							dataTestId="navigation__feedback-link--desktop"
						/>
					</div>
				</li>
			</ul>
		</nav>
	);
}

export default Navigation;
