import { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import EmailImage from '../Assets/PasswordReset/check_your_mail.svg';
import Input from '../Input/Input';
import RestService from '../RestService/RestService';
import { RECOVER_TEAM_NAME_PATH } from '../Routes/RouteConstants';

import './ResetPasswordPage.scss';

function ResetPasswordPage() {
	const [teamName, setTeamName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [messageSent, setMessageSent] = useState<boolean>(false);

	const onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		RestService.requestPasswordReset({ name: teamName, email })
			.then(() => setMessageSent(true))
			.catch(console.error);
	};

	return messageSent ? (
		<div className="form-container reset-password-page__acknowledge-container">
			<img
				className="reset-password-page__image"
				alt={''}
				src={EmailImage}
			/>
			<h1 className="reset-password-form__title">Check your mail!</h1>
			<p className="reset-password-form__text">
				If that team exists with the email address below, you’ll receive
				instructions in your inbox shortly.
			</p>
			<h2 className="reset-password-form__email">{email}</h2>
		</div>
	) : (
		<form className="form-container" onSubmit={onSubmit}>
			<h1>Reset Password</h1>
			<p>
				Enter the Team Name and email associated with your account and
				we’ll send an email with instructions to reset your password.
			</p>
			<Input
				label="Team name"
				testId="passwordResetForm__team-name"
				onChange={setTeamName}
			/>
			<Input
				label="Email address"
				type="email"
				testId="passwordResetForm__email"
				onChange={setEmail}
			/>
			<button
				className="button__primary"
				data-testid="passwordResetForm__submit-button"
			>
				Send instructions
			</button>
			<div className="reset-password-form__forgot-password-link-wrapper">
				<Link
					className="reset-password-form__forgot-password-link"
					data-testid="passwordResetForm__forgot-team-name-link"
					to={RECOVER_TEAM_NAME_PATH}
				>
					I don't know my team name either!
				</Link>
			</div>
		</form>
	);
}

export default ResetPasswordPage;
