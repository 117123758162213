import React, {ReactNode, useEffect, useRef} from 'react';
import FocusTrap from 'focus-trap-react';
import {useSetRecoilState} from 'recoil';

import {ModalContentsAtom} from '../State/ModalContentsAtom';
import useOnClickOutside from '../Utils/hooks/useOnClickOutside';

import './Modal.scss';

interface Props {
	children: ReactNode;
}

function Modal({ children }: Props): React.ReactElement {
	const setModalContents = useSetRecoilState(ModalContentsAtom);
	const ref = useRef(null);

	useEffect(() => {
		document.body.style.overflowY = 'hidden';

		return () => {
			document.body.style.overflowY = 'unset';
		};
	}, [children]);

	const closeModal = () => setModalContents(null);

	useOnClickOutside(ref, closeModal);

	return (
		<FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
			<div
				className="modal__background"
				data-testid="modal"
				aria-modal="true"
				role="dialog"
				aria-labelledby="modal_label"
			>
				<div className="modal__contents" ref={ref}>
					{children}
				</div>
			</div>
		</FocusTrap>
	);
}

export default Modal;
