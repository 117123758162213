import useGetSession from '../Utils/hooks/useGetSession';

import JoinActiveSessionPage from './JoinActiveSessionPage';
import LeadNewSessionPage from './LeadNewSessionPage';

import './HomePage.scss';

function HomePage() {
	const session = useGetSession();

	return (
		<div className="home-page" data-testid="homePage">
			{session ? <JoinActiveSessionPage /> : <LeadNewSessionPage />}
		</div>
	);
}

export default HomePage;
